const moment = require('moment-timezone');
require('moment/locale/es');  // Import the Spanish locale

/**
 * Date Format
 * @param {*} date 
 * @returns 
 */
function shortFormat(date,tz="America/Bogota") {
  return capitalize(moment(date).tz(tz).locale('es').format("MMM DD,  h:mm:ss A")).replace('.', '');
}

function longDateFormat(date,tz="America/Bogota") {
  let dateObject=moment(date).tz(tz).locale('es');
  return capitalize(dateObject.format("MMMM DD")).replace('.', '')+', '+capitalize(dateObject.format("dddd")).replace('.', '');
}

/**
 * Time ago
 * @param {*} date 
 * @returns 
 */
function timeAgo(date, tz = "America/Bogota") {
  let lang = 'es';

  moment.updateLocale('en', {
    calendar: {
      lastDay: "[Yesterday] h:mm A",
    }
  });
  moment.updateLocale('es', {
    calendar: {
      lastDay: "[Ayer] h:mm A",
    }
  });

  let momentdate = moment(date).tz(tz).locale(lang);

  let text = capitalize(momentdate.calendar(null, {
    sameDay: 'h:mm A',
    lastWeek: 'dddd',
    sameElse: 'L'
  }));

  return text;
}

/**
 * Hour Format 
 * @param {*} date 
 * @returns 
 */
function hourFormat(date,tz="America/Bogota") {
  return capitalize(moment(date).tz(tz).locale('es').format("HH:mm:ss"));
}

/**
 * Capitalize
 * @param {*} str 
 * @returns 
 */
function capitalize(str){
  return str.charAt(0).toUpperCase() + str.slice(1);
}

module.exports = { 
  shortFormat,
  timeAgo,
  hourFormat,
  longDateFormat
};