import React, { useEffect, useState } from 'react';
import { useAuth } from "@clerk/clerk-react";
import { Uppy } from '@uppy/core';
import Spanish from '@uppy/locales/lib/es_MX';
import { Dashboard } from '@uppy/react';
import XHRUpload from '@uppy/xhr-upload';
import ImageEditor from '@uppy/image-editor';
import { useParams } from "react-router-dom";
import { API_URL, PATH_MEDIA_UPLOAD } from "./utils/constants";
import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';

const max_file_size = 100;//MB
let uppyInstance;

const SettingsMediaUppy = ({ fetchFiles, height }) => {
	// IMPORTANT: passing an initializer function to prevent Uppy from being reinstantiated on every render.
	const { companyUrl } = useParams(); //Param
	const { getToken } = useAuth(); // Get the token

	useEffect(() => {
		const initializeUppy = async () => {
			const token = await getToken();

			// https://uppy.io/docs/uppy/
			const uppy = new Uppy({
				id: 'uppy-dashboard',
				autoProceed: false,
				allowMultipleUploadBatches: true,
				debug: false,
				locale: Spanish,
				restrictions: {
					maxFileSize: max_file_size * 1024 * 1024, // 50MB
					maxNumberOfFiles: 10,
					allowedFileTypes: [
						'image/*',
						'video/*',
						'audio/*',
						'.pdf',
						'.xlsx',
						'.xls',
						'.doc',
						'.docx'
					]
				}
			});

			uppy.use(XHRUpload, {
				endpoint: `${API_URL}company/${companyUrl}/${PATH_MEDIA_UPLOAD}`,
				fieldName: 'files',
				formData: true,
				headers: {
					// Add any auth headers if needed
					//Authorization: `Bearer 0`
				},
				onBeforeRequest: async (xhr) => {
					// Modify headers before the request is sent
					
					xhr.setRequestHeader('Authorization', `Bearer ${await getToken()}`);
					console.log('new token: '+JSON.stringify(xhr))
				}
				
			});

			uppy.use(ImageEditor);

			// Handle successful uploads
			uppy.on('upload-success', (file, response) => {
				const { body } = response;
				console.log('File uploaded successfully:', body);
				fetchFiles();
			});

			// Handle upload errors
			uppy.on('upload-error', (file, error, response) => {
				console.error('Upload error:', error, response);
			});

			uppy.on('file-added', (file) => {
				if (file.size > max_file_size * 1024 * 1024) {
					uppy.removeFile(file.id);
					alert('File too large');
				}
			});

			uppyInstance=uppy;
		};

		initializeUppy();

		return () => {
			if (uppyInstance) uppyInstance.destroy();
		};
	}, [getToken]);


	return (
		uppyInstance ?
			<Dashboard
				uppy={uppyInstance}
				theme="light"
				proudlyDisplayPoweredByUppy={false}
				height={height ?? 400}
				width="100%"
				className="bg-white rounded-lg shadow-lg"
			/> :
			<></>
	);
}

export default SettingsMediaUppy;