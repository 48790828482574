// src/Companies.js
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "@clerk/clerk-react";
import { useParams } from "react-router-dom";
import { API_URL,PATH_CHANNELS } from "./utils/constants";
import LoaderSymbol from "./icons/LoaderSymbol";
import FacebookSDK from "./FacebookEmbeddedLogin";


const SettingsCompany = ({ company, handlerToggleMenu, handlerShowMenu, handlerHideMenu, showMenu }) => {
  const { companyUrl, templateid } = useParams(); //Param
  const [waba_id, setWaba_id] = useState();
  const [code, setCode] = useState();
  const [fields, setFields] = useState([]);
  const [channels, setNumbers] = useState(null);
  const [defaultStatus, setDefaultStatus] = useState(null);
  const [statuses, setStatuses] = useState([]);
  const [statusLoading, setStatusLoading] = useState(false);
  const [agentPowerOn, setAgentPowerOn] = useState(null);
  const [result, setResult] = useState();
  const [statusUnsaved, setStatusUnsaved] = useState(false);
  const [error, setError] = useState(null);
  const { getToken } = useAuth(); // Get the token

  const dateFormatter = new Intl.DateTimeFormat('es', {
    year: 'numeric',
    month: 'short',// 3-letter month
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true
  });

  //Load template
  useEffect(() => {
    setError();
    setResult();
  }, [companyUrl, templateid]);

  //Update Page title
  useEffect(() => {
    let title = "Configuración";

    if (title) {
      document.title = `${title} - ${companyUrl}`;
    } else {
      document.title = `${companyUrl}`;
    }

    fetchChannels();
    getPowerOn();
    getStatuses();

  }, []);

  //Update Code
  useEffect(() => {
    if (waba_id && code) {
      saveToken();
    }
  }, [code, waba_id]);


  const saveToken = async () => {
    try {
      const token = await getToken(); // Get the token
      const response = await fetch(`${API_URL}company/${companyUrl}/settings/saveAccessToken`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          waba_id: waba_id,
          code: code,
          url: ""
        })
      });

      console.log("Token request: " + JSON.stringify({
        waba_id: waba_id,
        code: code,
        url: ""
      }));
      console.log("Token response: " + response.data);

      fetchChannels();

    } catch (error) {
      console.error("Error saving token: " + error.message);
    }
  }

  const fetchChannels = async () => {
      try {
        const token = await getToken(); // Get the token
        const response = await fetch(`${API_URL}company/${companyUrl}/${PATH_CHANNELS}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json"
          }
        });
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setNumbers(data);
      } catch (error) {
        console.error(error.message);
      }
    };

    const getStatuses = async () => {
      try {
        const token = await getToken(); // Get the token
        const response = await fetch(`${API_URL}company/${companyUrl}/statuses`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json"
          }
        });
        const data = await response.json();
        //console.log("Statuses: "+JSON.stringify(data))
        setStatuses(data);

        let defaultStatus = data.find(status => status.assistant_id === company.data.assistant_id);
        //console.log("Default Status: "+company.data.assistant_id);
        //console.log("Default Status: "+JSON.stringify(defaultStatus));
        
        setDefaultStatus(defaultStatus?.status);

      } catch (error) {
        console.error(error.message);
      }
    };

    const getPowerOn = async () => {
      try {
        const token = await getToken(); // Get the token
        const response = await fetch(`${API_URL}company/${companyUrl}/agentPower`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json"
          }
        });
        const data = await response.json();
        console.log("PowerOn: "+JSON.stringify(data))
        setAgentPowerOn(data);
      } catch (error) {
        console.error(error.message);
      }
    };

    const powerOn = async () => {
      try {
        const token = await getToken(); // Get the token
        const response = await fetch(`${API_URL}company/${companyUrl}/agentPowerOn`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json"
          }
        });
        if (response.ok) setAgentPowerOn(true);
      } catch (error) {
        console.error(error.message);
      }
    };

    const powerOff = async () => {
      try {
        const token = await getToken(); // Get the token
        const response = await fetch(`${API_URL}company/${companyUrl}/agentPowerOff`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json"
          }
        });
        if (response.ok) setAgentPowerOn(false);
      } catch (error) {
        console.error(error.message);
      }
    };


  const handleStatusChange = async (event) => {
    const newStatus = event.target.value;
    console.log("Default Status: " + newStatus);
    setDefaultStatus(newStatus);
    setStatusUnsaved(true);
  };  

    const saveStatus = async (event) => {
      try {
        setStatusLoading(true);
        const token = await getToken(); // Get the token
        const response = await fetch(`${API_URL}company/${companyUrl}/setDefaultStatus`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            status: defaultStatus
          })
        });
        if (response.ok){
          console.log("Default Status Updated: "+defaultStatus);
        }
      } catch (error) {
        console.error(error.message);  
      }
      setStatusLoading(false);
      setStatusUnsaved(false);
    }; 


  const handleWhatsAppSignup = async () => {
    // Launch Facebook login
    try {
      window.FB.login((response) => {
        if (response.authResponse) {
          const code = response.authResponse.code;
          console.log("handleWhatsAppSignup "+JSON.stringify(response, null, 2));
          
          // No await needed here since we're not using the return value
          fbLoginCallback(code);
        }
        else if(response.error) {
          console.error(response?.error);
        }
      }, {
        config_id: '571651532025887', // configuration ID goes here
        response_type: 'code', // must be set to 'code' for System User access token
        override_default_response_type: true, // when true, any response types passed in the "response_type" will take precedence over the default types
        extras: {
          setup: {},
          featureType: '',
          sessionInfoVersion: '2',
        }
      });
    }
    catch (error) {
      console.error("Error: " + error);
    }
  }

  /**
   * Login Callback
   * @param {*} code 
   */
  const fbLoginCallback = async (code) => {
    try {

      console.log("Token request: " + JSON.stringify({
        waba_id: waba_id,
        code: code,
        url: ""
      }));

      setCode(code);

    } catch (error) {
      console.error('Error:', error);
      setResult('Error:', error);
    }
  }

  useEffect(() => {
    const handleMessage = async (event) => {
      // Verify origin for security
      if (event.origin !== "https://www.facebook.com" && event.origin !== "https://web.facebook.com") {
        return;
      }

      try {
        const data = JSON.parse(event.data);

        try {
          const token = await getToken(); // Get the token
          let body=data;

          const response = await fetch(`${API_URL}company/${companyUrl}/settings/saveWABA`, {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json"
            },
            body: JSON.stringify(body)
          });
    
          console.log("Guardado: "+response.data);
    
        } catch (error) {
          console.error('Error:', error);
          setResult('Error:', error);
        }
        
        if (data.type === 'WA_EMBEDDED_SIGNUP') {
          switch (data.event) {
            case 'FINISH':
              const { phone_number_id, waba_id } = data.data;
              console.log("Phone number ID ", phone_number_id, " WhatsApp business account ID ", waba_id);

              setWaba_id(phone_number_id);
              
              // You can add your success handling logic here
              break;

            case 'CANCEL':
              const { current_step } = data.data;
              console.warn("Cancel at ", current_step);
              // You can add your cancel handling logic here
              break;

            case 'ERROR':
              const { error_message } = data.data;
              console.error("error ", error_message);
              // You can add your error handling logic here
              break;

            default:
              break;
          }
        }

        // Update the session info state with formatted JSON
        console.log(JSON.stringify(data, null, 2));

      } catch (error) {
        console.log('Non JSON Responses', event.data);
      }
    };

    // Add event listener when component mounts
    window.addEventListener('message', handleMessage);

    // Cleanup function to remove event listener when component unmounts
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []); // Empty dependency array means this runs once on mount



  if (error) {
    return (
      <div className="flex flex-col flex-auto grow h-full p-0 sm:p-6">
        <div className="flex flex-col flex-auto rounded-xl h-full bg-gray-100 p-0">
          <h4>Error: {error}</h4>
        </div>
      </div>
    );
    ;
  }

  if (!companyUrl) {
    return (
      <div className="flex flex-col flex-auto grow h-full p-0 sm:p-6">
        <div className="flex flex-col flex-auto rounded-xl h-full bg-gray-100 p-0">
          <h4>Empresa Inválida</h4>
        </div>
      </div>
    );
    ;
  }

      
  return (

    <div className={`${showMenu ? `hidden` : `flex`} flex-col flex-auto grow h-full p-0 sm:p-6`}>
      <div className="flex flex-col flex-auto rounded-xl h-full bg-gray-100 p-0">




        <div className="p-3 rounded-lg bg-white shadow rounded-xl w-100 m-4">

          <h2>Conectar Whatsapp</h2>

          <FacebookSDK />

          {waba_id && code ? <p>Línea conectada: ID {waba_id}</p> : <></>}

          {!waba_id || !code ? 
          <button
            onClick={handleWhatsAppSignup}
            style={{ backgroundColor: '#1877f2' }}
            className="mt-3 rounded px-6 h-10 text-white font-bold text-base cursor-pointer font-sans hover:bg-blue-600 transition-colors"
          >
            Login with Facebook
          </button>
          : <></>}

        </div>

        <div className="p-3 rounded-lg bg-white shadow rounded-xl w-100 m-4">

          <h2 className="mb-3">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-4 w-4 mr-2 inline">
            <path fillRule="evenodd" d="M2 3.5A1.5 1.5 0 0 1 3.5 2h1.148a1.5 1.5 0 0 1 1.465 1.175l.716 3.223a1.5 1.5 0 0 1-1.052 1.767l-.933.267c-.41.117-.643.555-.48.95a11.542 11.542 0 0 0 6.254 6.254c.395.163.833-.07.95-.48l.267-.933a1.5 1.5 0 0 1 1.767-1.052l3.223.716A1.5 1.5 0 0 1 18 15.352V16.5a1.5 1.5 0 0 1-1.5 1.5H15c-1.149 0-2.263-.15-3.326-.43A13.022 13.022 0 0 1 2.43 8.326 13.019 13.019 0 0 1 2 5V3.5Z" clipRule="evenodd" />
            </svg>
            Canales conectados
          </h2>

          { channels == null && <p>Cargando...</p> }

          { Array.isArray(channels) && channels?.length === 0 && <p>No hay canales conectados</p> }

          { Array.isArray(channels) && channels?.length > 0 &&
          <table id="numbers" className="table-auto w-full text-sm">
            <thead className="text-gray-700 uppercase bg-gray-50">
              <tr>
                <th className="p-4 border-b border-blue-gray-50">Canal</th>
                <th className="p-4 border-b border-blue-gray-50">Enlace</th>
                <th className="p-4 border-b border-blue-gray-50">Info</th>
              </tr>
            </thead>
            <tbody>
                {channels?.map((number) =>
                  number.source === "whatsapp" && number.meta_waba_id &&
                  (<tr key={number._id}>
                    <td className="p-4 border-b border-blue-gray-50">{number.source === "whatsapp" ? <img src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg" alt="WhatsApp" className="w-8 h-8 inline" /> : <></>} {number.verified_name}<br />{number.display_phone_number}</td>
                    <td className="p-4 border-b border-blue-gray-50"><a href={`https://wa.me/${number.number}`} className="underline">https://wa.me/{number.number}</a></td>
                    <td className="p-4 border-b border-blue-gray-50">
                      WA Business account: {number.meta_waba_id}<br />
                      WA Phone Number ID: {number.meta_phone_number_id}<br />
                      Fecha conexión: {dateFormatter.format(new Date(number.createdAt)) ?? ""}
                    </td>
                  </tr>)
                )
                }
            </tbody>
          </table>
          }
          

        </div>

        <div className="p-3 rounded-lg bg-white shadow rounded-xl w-100 m-4">

          <h2 className="mb-3">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-4 w-4 mr-2 inline">
              <path fillRule="evenodd" d="M12 2.25a.75.75 0 0 1 .75.75v9a.75.75 0 0 1-1.5 0V3a.75.75 0 0 1 .75-.75ZM6.166 5.106a.75.75 0 0 1 0 1.06 8.25 8.25 0 1 0 11.668 0 .75.75 0 1 1 1.06-1.06c3.808 3.807 3.808 9.98 0 13.788-3.807 3.808-9.98 3.808-13.788 0-3.808-3.807-3.808-9.98 0-13.788a.75.75 0 0 1 1.06 0Z" clipRule="evenodd" />
            </svg>
            Agente
          </h2>

          { agentPowerOn == null ?
          <div className="cursor-not-allowed">
            <div className="flex flex-row items-center mt-1 mb-1">
              <div className="flex flex-col justify-center h-4 w-8 bg-gray-500 rounded-full">
                <div className="h-3 w-3 bg-white rounded-full self-center"></div>
              </div>
            </div>
          </div>
          : <></>}

          { agentPowerOn === true ?
          <div onClick={(e) => { e.preventDefault(); powerOff(); }} className="cursor-pointer">
            <div className="flex flex-row items-center mt-1 mb-1">
              <div className="flex flex-col justify-center h-4 w-8 bg-green-500 rounded-full">
                <div className="h-3 w-3 bg-white rounded-full self-end mr-1"></div>
              </div>
            </div>
          </div>
          : <></>}

          { agentPowerOn === false ?
          <div onClick={(e) => { e.preventDefault(); powerOn(); }} className="cursor-pointer">
            <div className="flex flex-row items-center mt-1 mb-1">
              <div className="flex flex-col justify-center h-4 w-8 bg-red-600 rounded-full">
                <div className="h-3 w-3 bg-white rounded-full self-start ml-1"></div>
              </div>
            </div>
          </div> 
          : <></>}
          

        </div>


        {Array.isArray(statuses) && statuses.length > 0 &&
          <div className="p-3 rounded-lg bg-white shadow rounded-xl w-100 m-4">

            <h2 className="mb-3">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-4 w-4 mr-2 inline">
                <path strokeLinecap="round" strokeLinejoin="round" d="M7.5 21 3 16.5m0 0L7.5 12M3 16.5h13.5m0-13.5L21 7.5m0 0L16.5 12M21 7.5H7.5" />
              </svg>

              Estado Inicial
            </h2>
            <select 
            className="form-select form-select-lg mb-3 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block p-2"
            value={defaultStatus} 
            onChange={handleStatusChange}>
              {statuses.map((status) => (
                <option key={status.status} value={status.status}>🔀 {status.status}</option>
              ))}
            </select>
            <button
              className={(statusUnsaved ? `bg-green-500 hover:bg-green-700 ` : `bg-gray-500 cursor-not-allowed`)+` text-white py-2 px-4 rounded inline-block mr-2`}
              type="submit"
              id="sendButton"
              disabled={!statusUnsaved}
              onClick={saveStatus}
            >
              Guardar
            </button> {statusLoading ? <LoaderSymbol loading={statusLoading}/> : ""}
          </div>
        }




      </div>
    </div>
  );
};

export default SettingsCompany;
