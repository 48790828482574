import React, { useState,useRef,useEffect } from "react";
import data from '@emoji-mart/data';//https://github.com/missive/emoji-mart
import Picker from '@emoji-mart/react';

const EmojiPicker = ({handlePickEmoji}) => {
  const [showPicker, setShowPicker] = useState(false);
  const picker = useRef(null);

  const togglePicker = () => {
    setShowPicker(!showPicker);
  };

  const addEmoji = (emoji) => {
    handlePickEmoji(emoji.native);
    setShowPicker(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (picker.current && !picker.current.contains(event.target)) {
        setShowPicker(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  

  return (
    <div className="relative inline-block">
      <button onClick={togglePicker} className="inline-block hover:text-green-500 focus:outline-none focus:border-none">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" height="24" width="24" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="inline-block">
            <path strokeLinecap="round" strokeLinejoin="round" d="M15.182 15.182a4.5 4.5 0 0 1-6.364 0M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0ZM9.75 9.75c0 .414-.168.75-.375.75S9 10.164 9 9.75 9.168 9 9.375 9s.375.336.375.75Zm-.375 0h.008v.015h-.008V9.75Zm5.625 0c0 .414-.168.75-.375.75s-.375-.336-.375-.75.168-.75.375-.75.375.336.375.75Zm-.375 0h.008v.015h-.008V9.75Z" />
        </svg>
      </button>

      {showPicker && (
        <div className="absolute bottom-0 right-0 shadow-lg rounded z-50" ref={picker}>
            <Picker data={data} onEmojiSelect={addEmoji} locale="es"/>
        </div>
      )}
    </div>
  );
};

export default EmojiPicker;
