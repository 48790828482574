import React, { useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "@clerk/clerk-react";
import { API_URL,PATH_MEDIA } from "./utils/constants";
import { timeAgo } from "./utils/dateHelper";
import LoaderSymbol from "./icons/LoaderSymbol";
import SettingsMediaUppy from "./SettingsMediaUppy";

const MediaPicker = ({ handlePickMedia, markdown, index='', field='' }) => {
  const {companyUrl, contactid } = useParams(); //Param
  const [showPicker, setShowPicker] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState(null);
  const { getToken } = useAuth(); // Get the token

  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  const picker = useRef(null);

  const togglePicker = () => {
    setShowPicker(!showPicker);
    fetchMedia();
  };

  const addMedia = (event) => {
    let markdownMedia = `![](${event.target.dataset.url})`;
    if(!markdown) markdownMedia = `${event.target.dataset.url}`;
    if(!event.target) event.target = {};
    if(!event.target.dataset) event.target.dataset = {};
    event.target.dataset.index=index;
    event.target.dataset.field=field;
    handlePickMedia(markdownMedia, event);
    setShowPicker(false);
  };

  const addMediaUrl = (event) => {
    let markdownMedia = `![](${inputValue})`
    if(!markdown) markdownMedia = `${inputValue}`;
    if(!event.target) event.target = {};
    if(!event.target.dataset) event.target.dataset = {};
    event.target.dataset.index=index;
    event.target.dataset.field=field;
    handlePickMedia(markdownMedia, event);
    setShowPicker(false);
  };

  const fetchMedia = async () => {
      try {
        setLoading(true);
        const token = await getToken(); // Obtain the token
        const response = await fetch(`${API_URL}company/${companyUrl}/${PATH_MEDIA}?limit=20`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json"
          }
        });
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setFiles(data);
  
      } catch (error) {
        console.error(error.message);
      } finally {
        setLoading(false);
      }
    };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (picker.current && !picker.current.contains(event.target)) {
        setShowPicker(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  return (
    <div className="relative inline-block">
      <button onClick={togglePicker} className="inline-block hover:text-green-500 focus:outline-none focus:border-none">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" height="24" width="24" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="inline-block">
          <path strokeLinecap="round" strokeLinejoin="round" d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z" />
        </svg>
      </button>

      {showPicker && (
        <div className="fixed inset-0 sm:top-auto sm:left-auto sm:bottom-0 sm:right-0 shadow-lg rounded z-50 bg-white p-4" ref={picker}>

      <button onClick={togglePicker} className="inline-block right-0 mb-2">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h4 w-4 inline-block">
          <path d="M6.28 5.22a.75.75 0 0 0-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 1 0 1.06 1.06L10 11.06l3.72 3.72a.75.75 0 1 0 1.06-1.06L11.06 10l3.72-3.72a.75.75 0 0 0-1.06-1.06L10 8.94 6.28 5.22Z" />
        </svg> Close
      </button>

          <SettingsMediaUppy fetchFiles={fetchMedia} height={200}/>

          {files ?
            <div className="overflow-y-auto h-50 mt-2" style={{ maxHeight: "250px" }}>
            <div className="grid grid-cols-1 gap-4 my-4 mx-4">
              {Array.isArray(files) && files?.map((file) => (
                <div className="flex items-start space-x-4 w-full" key={file.name}>
                  <img
                    alt="gallery"
                    title={file.name}
                    data-url={file.url}
                    className="block rounded-lg w-16 h-16 object-cover shadow flex-shrink-0 cursor-pointer"
                    src={file.thumb} 
                    onClick={addMedia}
                  />
                  <div className="flex flex-col overflow-hidden">
                    <p className="text-xs whitespace-nowrap overflow-hidden text-overflow-ellipsis">{file.name}</p>
                    <p className="text-xs text-gray-500">{timeAgo(file.createdAt)}</p>
                    <p className="text-xs text-gray-500">{file.user}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
            : <></>
          }
          
          <div className="flex items-start space-x-4 w-full mt-2">
            <input
              type="text"
              className="px-3 py-2 border border-gray-300 rounded-md text-sm focus:outline-none focus:ring-2 focus:ring-blue-500 mb-2 w-64"
              placeholder="https://www.web.com/image.jpg"
              value={inputValue}
              onChange={handleChange}
            />
            <button
              type="button"
              className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-blue-500 mr-2"
              onClick={addMediaUrl}
            >
              OK
            </button>
          </div>
          <LoaderSymbol loading={loading} />
        </div>
      )}
    </div>
  );
};

export default MediaPicker;
