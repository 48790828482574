// src/Companies.js
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "@clerk/clerk-react";
import { useParams } from "react-router-dom";
import { API_URL, PATH_MEDIA } from "./utils/constants";
import { timeAgo, shortFormat } from "./utils/dateHelper";
import LoaderSymbol from "./icons/LoaderSymbol";
import SettingsMediaUppy from "./SettingsMediaUppy";

const SettingsMedia = ({ company, handlerToggleMenu, handlerShowMenu, handlerHideMenu, showMenu }) => {
  const { companyUrl, templateid } = useParams(); //Param
  const [files, setFiles] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { getToken } = useAuth(); // Get the token

  const fetchFiles = async () => {
    try {
      setLoading(true);
      const token = await getToken(); // Get the token
      const response = await fetch(`${API_URL}company/${companyUrl}/${PATH_MEDIA}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      console.log(data);
      setFiles(data);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };


  //Update Page title
  useEffect(() => {
    let title = "Media";

    if (title) {
      document.title = `${title} - ${companyUrl}`;
    } else {
      document.title = `${companyUrl}`;
    }

    fetchFiles();

  }, []);

  if (error) {
    return (
      <div className="flex flex-col flex-auto grow h-full p-0 sm:p-6">
        <div className="flex flex-col flex-auto rounded-xl h-full bg-gray-100 p-0">
          <h4>Error: {error}</h4>
        </div>
      </div>
    );
    ;
  }

  if (!companyUrl) {
    return (
      <div className="flex flex-col flex-auto grow h-full p-0 sm:p-6">
        <div className="flex flex-col flex-auto rounded-xl h-full bg-gray-100 p-0">
          <h4>Empresa Inválida</h4>
        </div>
      </div>
    );
    ;
  }


  return (

    <div className={`${showMenu ? `hidden` : `flex`} flex-col flex-auto grow h-full p-0 sm:p-6`}>
      <div className="flex flex-col flex-auto rounded-xl h-full bg-gray-100 p-0">

        <div className="flex flex-col h-full overflow-x-auto" id="media">

          <h2 className="p-2 my-2 mx-2 text-black">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4 mr-2 inline">
              <path fillRule="evenodd" d="M1 5.25A2.25 2.25 0 0 1 3.25 3h13.5A2.25 2.25 0 0 1 19 5.25v9.5A2.25 2.25 0 0 1 16.75 17H3.25A2.25 2.25 0 0 1 1 14.75v-9.5Zm1.5 5.81v3.69c0 .414.336.75.75.75h13.5a.75.75 0 0 0 .75-.75v-2.69l-2.22-2.219a.75.75 0 0 0-1.06 0l-1.91 1.909.47.47a.75.75 0 1 1-1.06 1.06L6.53 8.091a.75.75 0 0 0-1.06 0l-2.97 2.97ZM12 7a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z" clipRule="evenodd" />
            </svg>
            Media
          </h2>

          <SettingsMediaUppy fetchFiles={fetchFiles} />

          <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-6 gap-6 mt-4 mx-4">
            {Array.isArray(files) && files?.map((file) => (
              <div className="p-3 w-full rounded-lg bg-white shadow rounded-xl">

                <img
                  alt="gallery"
                  className="block rounded-lg w-full h-auto mb-2 shadow"
                  src={file.thumb} />

                <p className="text-sm">{file.name}</p>
                <p className="text-xs text-gray-500">({file.mimetype})</p>
                <p className="text-xs text-gray-500">{shortFormat(file.createdAt)}</p>
                <p className="text-xs text-gray-500">{file.user}</p>
              </div>
            ))}
          </div>

          {Array.isArray(files) && files.length === 0 &&
            <div className="p-3 rounded-lg bg-white shadow rounded-xl m-4">
              <h4>Sin archivos</h4>
            </div>
          }

          {files === null &&
            <div className="p-3 rounded-lg bg-white shadow rounded-xl w-100 m-4">
              <h4>Cargando...</h4>
            </div>
          }







        </div>
      </div>
    </div>
  );
};

export default SettingsMedia;
