// src/Companies.js
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "@clerk/clerk-react";
import { useParams } from "react-router-dom";
import { API_URL, PATH_MEDIA } from "./utils/constants";
import { timeAgo, shortFormat } from "./utils/dateHelper";
import LoaderSymbol from "./icons/LoaderSymbol";
import SettingsMediaUppy from "./SettingsMediaUppy";

const SettingsAnalytics = ({ company, handlerToggleMenu, handlerShowMenu, handlerHideMenu, showMenu }) => {
  const { companyUrl, templateid } = useParams(); //Param
  const [files, setFiles] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { getToken } = useAuth(); // Get the token

  const fetchFiles = async () => {
    try {
      setLoading(true);
      const token = await getToken(); // Get the token
      const response = await fetch(`${API_URL}company/${companyUrl}/${PATH_MEDIA}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      console.log(data);
      setFiles(data);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };


  //Update Page title
  useEffect(() => {
    let title = "Analítica";

    if (title) {
      document.title = `${title} - ${companyUrl}`;
    } else {
      document.title = `${companyUrl}`;
    }

  }, []);

  if (error) {
    return (
      <div className="flex flex-col flex-auto grow h-full p-0 sm:p-6">
        <div className="flex flex-col flex-auto rounded-xl h-full bg-gray-100 p-0">
          <h4>Error: {error}</h4>
        </div>
      </div>
    );
    ;
  }

  if (!companyUrl) {
    return (
      <div className="flex flex-col flex-auto grow h-full p-0 sm:p-6">
        <div className="flex flex-col flex-auto rounded-xl h-full bg-gray-100 p-0">
          <h4>Empresa Inválida</h4>
        </div>
      </div>
    );
    ;
  }


  return (

    <div className={`${showMenu ? `hidden` : `flex`} flex-col flex-auto grow h-full p-0 sm:p-6`}>
      <div className="flex flex-col flex-auto rounded-xl h-full bg-gray-100 p-0">

        <div className="flex flex-col h-full overflow-x-auto" id="media">

          <h2 className="p-2 my-2 mx-2 text-black">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4 mr-2 inline">
                  <path fillRule="evenodd" d="M15.22 6.268a.75.75 0 0 1 .968-.431l5.942 2.28a.75.75 0 0 1 .431.97l-2.28 5.94a.75.75 0 1 1-1.4-.537l1.63-4.251-1.086.484a11.2 11.2 0 0 0-5.45 5.173.75.75 0 0 1-1.199.19L9 12.312l-6.22 6.22a.75.75 0 0 1-1.06-1.061l6.75-6.75a.75.75 0 0 1 1.06 0l3.606 3.606a12.695 12.695 0 0 1 5.68-4.974l1.086-.483-4.251-1.632a.75.75 0 0 1-.432-.97Z" clipRule="evenodd" />
                </svg>
            Analytics
          </h2>

          
          

        </div>
      </div>
    </div>
  );
};

export default SettingsAnalytics;
