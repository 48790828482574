import React, { useEffect, useState } from "react";
import { useAuth } from "@clerk/clerk-react";
import { API_URL,PATH_POWER_ON,PATH_POWER_OFF,PATH_UNTAG } from "./utils/constants";
import ConversationHeaderDropMenu from "./ConversationHeaderDropMenu";
import LoaderSymbol from "./icons/LoaderSymbol";


const ConversationHeader = ({ conversation, handlerUpdate, tags, statuses, handlerToggleMenu, updating }) => {
  const [agentConnected, setAgentConnected] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const { getToken } = useAuth(); // Get the token


  const powerOff = async () => {
    try {
      setLoading(true);
      const token = await getToken(); // Get the token
      const response = await fetch(`${API_URL}company/${conversation.contact?.company}/${conversation.contact?.contactid}/${PATH_POWER_OFF}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      });
      if(response.status === 200){
        setAgentConnected(false);
        console.log("POWER OFF "+conversation.contact?.contactid);
        handlerUpdate();
      }
    } catch (error) {
      setError(error.message);
    }
    setLoading(false);
  };

  const powerOn = async () => {
    try {
      setLoading(true);
      const token = await getToken(); // Get the token
      const response = await fetch(`${API_URL}company/${conversation.contact?.company}/${conversation.contact?.contactid}/${PATH_POWER_ON}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      });
      if(response.status === 200){
        setAgentConnected(true);
        console.log("POWER ON "+conversation.contact?.contactid);
        handlerUpdate();
      }
    } catch (error) {
      setError(error.message);
    }
    setLoading(false);
  };

  const untag = async (tag) => {
    try {
      setLoading(true);
      const token = await getToken(); // Get the token
      const response = await fetch(`${API_URL}company/${conversation.contact?.company}/${conversation.contact?.contactid}/${PATH_UNTAG}/${tag}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      });
      if(response.status === 200){
        console.log("UNTAG "+tag);
        handlerUpdate();
      }
    } catch (error) {
      setError(error.message);
    }
    setLoading(false);
  };

  const showMenu = async (tag) => {
    try {
      handlerToggleMenu();
    } catch (error) {
      console.log(error)
    }
  };

  useEffect(() => {
    setAgentConnected(conversation?.contact?.agent_connected);
  }, [conversation]);
  

  if (error) {
    return <div>Error: {error}</div>;
  }


  //console.log(conversation);

  return (
    <div className="flex flex-row flex-shrink-0 items-center h-16 rounded-t-xl bg-white w-full p-2 sm:p-4 shadow z-40" id="header">

        { updating && <div className="mr-2"><LoaderSymbol loading={true} /></div>}
        
        <div id="toggleMenu" className="sm:hidden flex-none mr-6" onClick={(e) => { e.preventDefault(); showMenu(); }}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" width="20" height="20" className="cursor-pointer">
            <path fillRule="evenodd" d="M2 4.75A.75.75 0 0 1 2.75 4h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 4.75ZM2 10a.75.75 0 0 1 .75-.75h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 10Zm0 5.25a.75.75 0 0 1 .75-.75h14.5a.75.75 0 0 1 0 1.5H2.75a.75.75 0 0 1-.75-.75Z" clipRule="evenodd" />
          </svg>
        </div>
        

        <div id="agentConnected">
          { agentConnected === true ?
          <div onClick={(e) => { e.preventDefault(); powerOff(); }} className="cursor-pointer">
            <div className="flex flex-row items-center mt-1 mb-1">
              <div className="flex flex-col justify-center h-4 w-8 bg-green-500 rounded-full">
                <div className="h-3 w-3 bg-white rounded-full self-end mr-1"></div>
              </div>
            </div>
          </div>
          : <></>}

          { agentConnected === false ?
          <div onClick={(e) => { e.preventDefault(); powerOn(); }} className="cursor-pointer">
            <div className="flex flex-row items-center mt-1 mb-1">
              <div className="flex flex-col justify-center h-4 w-8 bg-red-600 rounded-full">
                <div className="h-3 w-3 bg-white rounded-full self-start ml-1"></div>
              </div>
            </div>
          </div> 
          : <></>}

        </div>

        <div className="flex-grow ml-4">
          <div className="ml-1 text-base md:text-lg">
            {
              (conversation?.contact?.name)?
              <>{conversation?.contact?.name} <small className="text-gray-500 hover:text-gray-700 pr-2">{conversation?.contact?.contactid}</small></>:
              (conversation?.contact?.contactid)?`${conversation?.contact?.contactid}`:''
            }

            <LoaderSymbol loading={loading} />

            {(conversation?.contact?.status)?<small className="text-gray-500 hover:text-gray-700 pr-2">🔀{conversation.contact.status}</small>:''}


              {conversation?.contact?.tag_details?.map((tag) => (
                <span 
                key={tag.url}
                className="rounded-full font-normal text-xs text-white px-2 py-1 mr-1 border" 
                style={{
                  background: tag.backgroundColor ? tag.backgroundColor : '#DDD',
                  color: tag.textColor ? tag.textColor : '#000',
                  borderColor: tag.textColor ? tag.textColor : '#000',
                }}>
                  {tag.name} <div onClick={(e) => { e.preventDefault(); untag(tag.url); }} className="inline-block cursor-pointer">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16" fill="currentColor" className="inline-block">
                      <path d="M5.28 4.22a.75.75 0 0 0-1.06 1.06L6.94 8l-2.72 2.72a.75.75 0 1 0 1.06 1.06L8 9.06l2.72 2.72a.75.75 0 1 0 1.06-1.06L9.06 8l2.72-2.72a.75.75 0 0 0-1.06-1.06L8 6.94 5.28 4.22Z" />
                    </svg>
                  </div>
                </span>
              ))}

          </div>
        </div>

        {(conversation?.contact?.contactid)?
        <ConversationHeaderDropMenu conversation={conversation} handlerUpdate={handlerUpdate} tags={tags} statuses={statuses} setLoading={setLoading}/>
        :<></>}
        
    </div>
  );
};

export default ConversationHeader;
