import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer, CartesianGrid,ReferenceLine } from 'recharts';



export default function Chart({data, isHourly}) {

  let formattedData;
  let average = 0;
  if(Array.isArray(data)){

    formattedData = data?.map(item => ({
      date: isHourly ? new Date(item.date).toLocaleTimeString('en-US', { month: 'numeric', day: 'numeric', hour: 'numeric' }) : new Date(item.date).toLocaleDateString('en-US', { month: 'short', day: 'numeric' }),
      conversaciones: Number(item.total),
      transacciones: Number(item.transactions),
      conversion: Number((Number(item.conversion)*100).toFixed(2))
    }));

    return (
      <ResponsiveContainer width="100%" height={300}>
        <LineChart data={formattedData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date"/>
          <YAxis yAxisId="left" />
          <YAxis yAxisId="right" orientation="right" tickFormatter={tick => `${tick} %` } domain={[0, 'auto']}/>
          <Tooltip />
          <Line type="monotone" yAxisId="left" dataKey="conversaciones" stroke="#005f78" strokeWidth={2} isAnimationActive={false}/>
          <Line type="monotone" yAxisId="left" dataKey="transacciones" stroke="#48bb78" strokeWidth={2} isAnimationActive={false}/>
          <Line type="monotone" yAxisId="right" dataKey="conversion" stroke="#82181a" strokeWidth={2} isAnimationActive={false}/>
        </LineChart>
      </ResponsiveContainer>
    );
  }
  else{
    return (
      <p>Invalid data</p>
    );
  }

  
}