// src/Companies.js
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "@clerk/clerk-react";
import { useParams } from "react-router-dom";
import Chart from "./SettingsAnalyticsChart";
import { API_URL, PATH_ANALYTICS } from "./utils/constants";
import { longDateFormat, hourFormat } from "./utils/dateHelper";
import LoaderSymbol from "./icons/LoaderSymbol";

const SettingsAnalyticsConversations = ({ handlerToggleMenu, handlerShowMenu, handlerHideMenu, showMenu }) => {
  const { companyUrl, templateid } = useParams(); //Param
  const [data, setData] = useState(null);
  const [timePeriod, setTimePeriod] = useState(7);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [company, setCompany] = useState([]);
  const { getToken } = useAuth(); // Get the token

  const fetchCompany = async () => {
    try {
      const token = await getToken(); // Get the token
      //console.log(`fetchCompany ${companyUrl}`)
      const response = await fetch(`${API_URL}company/${companyUrl}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setCompany(data);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchData = async () => {
    try {
      setLoading(true);

      function roundToPreviousHour(date) {
        const roundedDate = new Date(date);
        roundedDate.setMinutes(0);
        roundedDate.setSeconds(0);
        roundedDate.setMilliseconds(0);
        return roundedDate;
      }
      function roundToPreviousDay(date) {
        const roundedDate = new Date(date);
        roundedDate.setMinutes(0);
        roundedDate.setSeconds(0);
        roundedDate.setMilliseconds(0);
        return roundedDate;
      }

      let endDate = roundToPreviousHour(new Date()).toISOString();
      let start = roundToPreviousHour(new Date());
      start.setDate(start.getDate() - parseInt(timePeriod));
      let startDate = start.toISOString();
      if(timePeriod > 1) startDate = roundToPreviousDay(start).toISOString();

      //console.log(`startDate ${startDate} endDate ${endDate}`);

      const token = await getToken(); // Get the token
      const response = await fetch(`${API_URL}company/${companyUrl}/${PATH_ANALYTICS}/conversations?startDate=${startDate}&endDate=${endDate}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      //console.log(data);
      setData(data);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const updateTimePeriod = async (days) => {

    console.log(`updateTimePeriod ${days} days`);

    if (days == 1) setTimePeriod(1);
    else if (days == 7) setTimePeriod(7);
    else if (days == 15) setTimePeriod(15);
    else if (days == 30) setTimePeriod(30);
    else if (days == 90) setTimePeriod(90);
    else if (days == 183) setTimePeriod(183);
    else if (days == 365) setTimePeriod(365);
    else setTimePeriod(7);
  };


  useEffect(() => {
    fetchData();
  }, [timePeriod]);

  useEffect(() => {
    fetchCompany();
  }, []);
  


  //Update Page title
  useEffect(() => {
    let title = "Analítica";

    if (title) {
      document.title = `${title} - ${companyUrl}`;
    } else {
      document.title = `${companyUrl}`;
    }

  }, []);

  const downloadCSV = () => {
    const csvContent = [
      ['Fecha', 'Conversaciones'],
      ...data.map(row => [
        timePeriod == 1 ? row.date : row.date.split('T')[0],
        row.total
      ])
    ].map(e => e.join(",")).join("\n");

    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `AtriaConversations-${companyUrl}-${timePeriod}days-${new Date().toISOString()}.csv`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  if (error) {
    return (
      <div className="flex flex-col flex-auto grow h-full p-0 sm:p-6">
        <div className="flex flex-col flex-auto rounded-xl h-full bg-gray-100 p-0">
          <h4>Error: {error}</h4>
        </div>
      </div>
    );
    ;
  }

  if (!companyUrl) {
    return (
      <div className="flex flex-col flex-auto grow h-full p-0 sm:p-6">
        <div className="flex flex-col flex-auto rounded-xl h-full bg-gray-100 p-0">
          <h4>Empresa Inválida</h4>
        </div>
      </div>
    );
    ;
  }


  return (

    <div className={`${showMenu ? `hidden` : `flex`} flex-col flex-auto grow h-full p-0 sm:p-6`}>
      <div className="flex flex-col flex-auto rounded-xl h-full bg-gray-100 p-0">

        <div className="flex flex-col h-full overflow-x-auto" id="media">

          <h2 className="p-2 my-2 mx-2 text-black">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4 mr-2 inline">
              <path fillRule="evenodd" d="M15.22 6.268a.75.75 0 0 1 .968-.431l5.942 2.28a.75.75 0 0 1 .431.97l-2.28 5.94a.75.75 0 1 1-1.4-.537l1.63-4.251-1.086.484a11.2 11.2 0 0 0-5.45 5.173.75.75 0 0 1-1.199.19L9 12.312l-6.22 6.22a.75.75 0 0 1-1.06-1.061l6.75-6.75a.75.75 0 0 1 1.06 0l3.606 3.606a12.695 12.695 0 0 1 5.68-4.974l1.086-.483-4.251-1.632a.75.75 0 0 1-.432-.97Z" clipRule="evenodd" />
            </svg>
            Conversaciones
          </h2>

          <div className="p-4">

            <select
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block p-2"
              value={timePeriod}
              onChange={(event) => updateTimePeriod(event.target.value)}
            >
              <option value="1" key={1}>24 horas</option>
              <option value="7" key={7}>1 semana</option>
              <option value="15" key={15}>2 semanas</option>
              <option value="30" key={30}>1 mes</option>
              <option value="90" key={90}>3 meses</option>
              <option value="183" key={183}>6 meses</option>
              <option value="365" key={365}>1 año</option>
            </select>

            <h2 className="p-2 my-2 mx-2 text-black text-center">{timePeriod == 1 ? `Conversaciones en las últimas 24 horas` : `Conversaciones en los últimos ${timePeriod} días`}</h2>

            {
              loading &&
              <LoaderSymbol loading={loading} />
            }

            {
              data && data.length > 0 && !loading &&
              <>
                <Chart data={data} isHourly={timePeriod == 1} />

                <button onClick={downloadCSV} className="mt-4 px-4 py-2 border border-gray-300 bg-gray-200 rounded hover:bg-gray-300 text-sm">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4 mr-2 inline">
                    <path fillRule="evenodd" d="M12 2.25a.75.75 0 0 1 .75.75v11.69l3.22-3.22a.75.75 0 1 1 1.06 1.06l-4.5 4.5a.75.75 0 0 1-1.06 0l-4.5-4.5a.75.75 0 1 1 1.06-1.06l3.22 3.22V3a.75.75 0 0 1 .75-.75Zm-9 13.5a.75.75 0 0 1 .75.75v2.25a1.5 1.5 0 0 0 1.5 1.5h13.5a1.5 1.5 0 0 0 1.5-1.5V16.5a.75.75 0 0 1 1.5 0v2.25a3 3 0 0 1-3 3H5.25a3 3 0 0 1-3-3V16.5a.75.75 0 0 1 .75-.75Z" clipRule="evenodd" />
                  </svg>
                  CSV
                </button>

                <table className="border-collapse border border-gray-300 mt-4">
                  <thead>
                    <tr className="bg-gray-200">
                      <th className="border p-2 text-center">Fecha</th>
                      <th className="border p-2 text-center">Conversaciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Array.isArray(data) && data.map((row, index) => (
                      <tr key={index} className="bg-white">
                        <td className="border p-2">{`${timePeriod == 1 ? row.date : row.date}`}</td>
                        <td className="border p-2 text-center">{row.total}</td>
                      </tr>
                    ))}
                    <tr key={'total'} className="">
                      <td className="border p-2 text-right">{`Total`}</td>
                      <td className="border p-2 text-center">{data.reduce((total, row) => total + parseInt(row.total), 0)}</td>
                    </tr>
                    <tr key={'promedio'} className="">
                      <td className="border p-2 text-right">{`Promedio`}</td>
                      <td className="border p-2 text-center">
                        {data.length > 0 
                          ? (data.reduce((total, row) => total + parseInt(row.total), 0) / data.length).toFixed(2) 
                          : 0}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </>

            }
          </div>


        </div>
      </div>
    </div>
  );
};

export default SettingsAnalyticsConversations;
