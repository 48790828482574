import { useEffect } from 'react';

const FacebookSDK = () => {
    useEffect(() => {
      // Load the SDK asynchronously
      const loadFacebookSDK = () => {
        window.fbAsyncInit = function() {
          window.FB.init({
            appId: '1568248643776755',
            autoLogAppEvents: true,
            xfbml: true,
            version: 'v22.0'
          });
        };
  
        // Load the SDK script
        (function(d, s, id) {
          var js, fjs = d.getElementsByTagName(s)[0];
          if (d.getElementById(id)) return;
          js = d.createElement(s);
          js.id = id;
          js.src = "https://connect.facebook.net/en_US/sdk.js";
          js.crossOrigin = "anonymous";
          fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));
      };
  
      loadFacebookSDK();
    }, []); // Empty dependency array means this runs once on mount
  
    return null; // This component doesn't render anything
  };
  
  export default FacebookSDK;