// src/Companies.js
import React, { useEffect, useState } from "react";
import { useAuth } from "@clerk/clerk-react";
import { useParams } from "react-router-dom";
import { API_URL, PATH_CONTEXTS } from "./utils/constants";
import LoaderSymbol from "./icons/LoaderSymbol";
import MediaPicker from "./MediaPicker";
import Editor from '@monaco-editor/react'; //https://github.com/suren-atoyan/monaco-react


const SettingsContextsItem = ({ context }) => {
  const { companyUrl } = useParams(); //Param
  const [loading, setLoading] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [lastUpdated, setLastUpdated] = useState();
  const [error, setError] = useState(null);
  const [contextValue, setContextValue] = useState();
  const [searchTerm, setSearchTerm] = useState('');
  const { getToken } = useAuth(); // Get the token

  const timeFormat={ 
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true 
  }

  useEffect(() => {
    if (context?.data) {

      let data = context.data;
      //console.log(data);

      //Last updated
      if (context.createdAt) {
        let lastUpdatedDate = new Date(context.createdAt);
        if (lastUpdatedDate) {
          const timeShort = lastUpdatedDate.toLocaleTimeString('es-ES', timeFormat);
          setLastUpdated(timeShort);
        }
        else setLastUpdated(context.createdAt);
      }

      //If catalog, return the object
      if (context?.type && (context.type === "catalog")) {
        setContextValue(data);
      }
      else if (data instanceof Object) {
        //Else, Check if its an object an parse it
        data = JSON.stringify(data, null, '\t');
        setContextValue(data);
      }
      else {
        //Else, return the string
        setContextValue(data);
      }

    }
  }, [context?.data]);

  useEffect(() => {

    //Unload Event
    const handleBeforeUnload = (event) => {
      if (updated) {
        event.preventDefault();
        event.returnValue = "Tienes cambios sin guardar. Seguro quieres salir sin guardar?";
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };

  }, [updated]);


  const handleSaveContext = async () => {
    try {

      setLoading(true);
      let newContext = {
        company: context.company,
        function: context.function,
        data: contextValue,
        description: context.description,
        type: context.type,
        createdAt: new Date()
      };
      if (context.editable) newContext.editable = context.editable;

      const token = await getToken(); // Get the token

      console.log(newContext);

      const response = await fetch(`${API_URL}company/${companyUrl}/${PATH_CONTEXTS}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(newContext)
      });

      if (response.ok) {
        setUpdated(false);
        const timeShort = (new Date()).toLocaleTimeString('es-ES', timeFormat);
        setLastUpdated(timeShort);
        setError(null);
      }
      else setError(response.statusText); 
      console.log(response);

    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdatedContent = async (event) => {
    if(!updated) setUpdated(true);
    setContextValue(event.target.value);
  };

  const handleUpdatedContentJson = async (newValue) => {
    if(!updated) setUpdated(true);
    setContextValue(newValue);
  };

  const handleUpdatedContentCatalog = async (event) => {
    const index = parseInt(event.target.dataset.index, 10);
    const field=event.target.dataset.field;

    if(!updated) setUpdated(true);

    let newJson=structuredClone(contextValue);
    newJson[index][field] = event.target.type === "checkbox" ? event.target.checked : event.target.value;
    setContextValue(newJson);
  };

  const searchProducts = async (event) => {
    setSearchTerm(event.target.value);
  };

  const handleAddProduct = () => {
    const newProduct = {
      name: "",
      description: "",
      image: "",
      price: 0,
      availability: true,
    };
    setContextValue([...contextValue, newProduct]);
    if(!updated) setUpdated(true);
  };

  const confirmDeleteProduct = (product) => {
    if (window.confirm(`¿Estás seguro de eliminar el producto "${product.name}"?`)) {
      handleDeleteProduct(product);
    }
  };

  const handleDeleteProduct = (product) => {
    const updatedContextValue = contextValue.filter((item) => item !== product);
    setContextValue(updatedContextValue);
    if(!updated) setUpdated(true);
  };

  const handlePickMedia= (textMedia) => {
    setContextValue((prevValue) => prevValue + textMedia);
    console.log(textMedia);
  };

  const handlePickMediaCatalog= (textMedia, event) => {

    const index = parseInt(event.target.dataset.index, 10);
    const field=event.target.dataset.field;
    console.log(`Index: ${index}, Field: ${field}`);

    setContextValue((prevValue) => prevValue + textMedia);
    console.log(textMedia);

    if(!updated) setUpdated(true);

    let newJson=structuredClone(contextValue);
    newJson[index][field] = textMedia;
    setContextValue(newJson);
  };

  return (

    <div className="p-3 rounded-lg bg-white shadow rounded-xl w-100 m-4" key={context._id}>
      

      {((context?.type && context.type === "json")) &&
        <>
          <h3 className="capitalize">{context.function}</h3>
          {context.description && <p className="text-gray-500">{context.description}</p>}
          <Editor
            id="editor"
            height="400px"
            defaultLanguage="json"
            value={contextValue}
            options={{ minimap: { enabled: false } }}
            className="shadow  my-4"
            onChange={handleUpdatedContentJson}
          />
        </>
        
      }

      {((context?.type && context.type === "catalog")) &&
        <>
          <h3 className="capitalize">{context.function}</h3>
          {context.description && <p className="text-gray-500">{context.description}</p>}
          <p className="text-sm">{Array.isArray(contextValue) && contextValue.length} {Array.isArray(contextValue) && contextValue.length !== 1 ? "productos" : "producto"}</p>

          <input type="text" value={searchTerm} onChange={searchProducts} placeholder="Buscar producto..." className="text-sm w-full border rounded focus:outline-none px-3 py-2 my-4 h-10 shadow-inner resize-none"/>
          
          <table className="w-full mb-4">
            <thead>  
              <tr>
                <th className="text-center">Nombre</th>
                <th className="text-center">Foto</th>
                <th className="text-center">Descripción</th>
                <th className="text-center">Precio</th>
                <th className="text-center">Disponibilidad</th>
                <th className="text-center">Acciones</th>
              </tr>
            </thead>
            <tbody>
          {
             // Check if contextValue is an array
            contextValue?.map((item, index) => {

              const matchesSearch =
              item.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
              item.description?.toLowerCase().includes(searchTerm.toLowerCase()) ||
              (item.image && item.image?.toLowerCase().includes(searchTerm.toLowerCase())) ||
              item.price?.toString().includes(searchTerm);

              if(!matchesSearch) return (<tr key={index} className={``}></tr>);


              return(
              <tr key={index} className={`border-b border-gray-200 ${item.availability ? "" : "bg-red-100"}`}>
                <td className="text-left p-2">
                  <p className="text-sm">{(index+1)}.</p>
                  <input type="text" value={item.name} onChange={handleUpdatedContentCatalog} className="p-2 shadow w-full my-4 h-auto text-sm bg-gray-100" data-index={index} data-field="name"/>
                </td>
                <td className="text-left p-2">
                  { item.image && <img src={item.image} alt={item.name} className="w-32 h-auto my-4" /> }
                  <div className="">
                    <MediaPicker handlePickMedia={handlePickMediaCatalog} index={index} field="image" markdown={false}/>
                  </div>
                  <input type="hidden" value={item.image} onChange={handleUpdatedContentCatalog} className="p-2 shadow w-full my-4 h-auto text-sm bg-gray-100" data-index={index} data-field="image"/>
                  
                </td>
                <td className="text-left p-2">
                  <textarea value={item.description} rows="4" onChange={handleUpdatedContentCatalog} className="p-2 shadow w-full my-4 h-auto text-sm bg-gray-100" data-index={index} data-field="description"/>
                </td>
                <td className="text-left p-2">
                  <input type="number" value={parseFloat(item.price)} onChange={handleUpdatedContentCatalog} className="p-2 shadow w-full my-4 h-auto text-sm bg-gray-100" data-index={index} data-field="price"/>
                </td>
                <td className={`text-center  p-2`}>
                  <input type="checkbox" value={item.availability?true:false} onChange={handleUpdatedContentCatalog} checked={item.availability} data-index={index} data-field="availability"/>
                </td>
                <td className="text-center">
                  <button
                    className={(updated ? `bg-green-500 hover:bg-green-700 ` : `bg-gray-500 cursor-not-allowed`)+` text-white font-bold py-2 px-4 rounded inline-block mr-2`}
                    type="submit"
                    id="sendButton"
                    onClick={handleSaveContext}
                    disabled={!updated}
                  >{loading ? <LoaderSymbol /> : ""}
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4 inline">
                      <path fillRule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z" clipRule="evenodd" />
                    </svg>
                  </button>
                  {error ? <span className="text-gray-500 text-sm">Error: {error}</span> : ""}

                  <button
                    className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded inline-block"
                    onClick={() => confirmDeleteProduct(item)}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4 inline">
                      <path fillRule="evenodd" d="M8.75 1A2.75 2.75 0 0 0 6 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 1 0 .23 1.482l.149-.022.841 10.518A2.75 2.75 0 0 0 7.596 19h4.807a2.75 2.75 0 0 0 2.742-2.53l.841-10.52.149.023a.75.75 0 0 0 .23-1.482A41.03 41.03 0 0 0 14 4.193V3.75A2.75 2.75 0 0 0 11.25 1h-2.5ZM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4ZM8.58 7.72a.75.75 0 0 0-1.5.06l.3 7.5a.75.75 0 1 0 1.5-.06l-.3-7.5Zm4.34.06a.75.75 0 1 0-1.5-.06l-.3 7.5a.75.75 0 1 0 1.5.06l.3-7.5Z" clipRule="evenodd" />
                    </svg>
                  </button>

                </td>
              </tr>
              )
            })
          }
            </tbody>
          </table>

          <div className="text-center">
            <button
              className="bg-green-500 hover:bg-green-700 text-white px-4 py-1 rounded mb-4"
              onClick={() => handleAddProduct()}
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-6 h-6 inline">
                <path d="M10.75 4.75a.75.75 0 0 0-1.5 0v4.5h-4.5a.75.75 0 0 0 0 1.5h4.5v4.5a.75.75 0 0 0 1.5 0v-4.5h4.5a.75.75 0 0 0 0-1.5h-4.5v-4.5Z" />
              </svg>
              Agregar Producto
            </button>
          </div>
        </>
      }

      

      {context?.type && context.type === "file" &&
        <>
          <h3 className="capitalize">{context.function}</h3>
          {context.description && <p className="text-gray-500">{context.description}</p>}
          <span className="text-gray-500 text-sm">
            <a href={context?.data} className="underline">
              {context?.data} 
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="pl-2 w-6 h-6 inline">
                <path strokeLinecap="round" strokeLinejoin="round" d="M13.19 8.688a4.5 4.5 0 0 1 1.242 7.244l-4.5 4.5a4.5 4.5 0 0 1-6.364-6.364l1.757-1.757m13.35-.622 1.757-1.757a4.5 4.5 0 0 0-6.364-6.364l-4.5 4.5a4.5 4.5 0 0 0 1.242 7.244" />
              </svg>
            </a>
          </span>

          <div className="flex flex-row flex-shrink-0">
            <div className="flex-grow">
              <input type="text"
                className="p-2 shadow w-full my-4 h-auto text-sm bg-gray-100"
                value={contextValue}
                onChange={handleUpdatedContent} />
            </div>
            <div className="ml-4 pt-6">
              <MediaPicker handlePickMedia={handlePickMedia} markdown={false}/>
            </div>
          </div>
        </>
        
      }

      {context?.type && context.type === "text" &&

        <div className="flex">
          <div className="mt-6">
            <label className="py-4 pr-4 capitalize">{context.function}</label>
          </div>
          <div className="my-4">
            <input type="text"
              className="p-2 shadow w-full h-auto text-sm bg-gray-100"
              value={contextValue}
              onChange={handleUpdatedContent} />
              {context.description && <p className="text-gray-500">{context.description}</p>}
          </div>
        </div>

      }

      {((!context?.type) || (context?.type && context.type === "textarea")) &&
        <>
          <h3 className="capitalize">{context.function}</h3>
          {context.description && <p className="text-gray-500">{context.description}</p>}
          <textarea type="text"
            className="p-2 shadow w-full my-4 h-auto text-sm bg-gray-100"
            rows="10"
            value={contextValue}
            onChange={handleUpdatedContent} />
        </>
      }

      {context?.type && context.type === "date" &&
        <div className="flex">
          <div className="mt-6">
            <label className="py-4 pr-4 capitalize">{context.function}</label>
          </div>
          <div className="my-4">
            <input type="date"
              className="p-2 shadow w-full h-auto text-sm bg-gray-100"
              value={contextValue}
              onChange={handleUpdatedContent} />
              {context.description && <p className="text-gray-500">{context.description}</p>}
          </div>
        </div>
      }

      <div className="flex">
        <div className="col-span-2">

          <button
            className={`flex items-center justify-center ${updated ? `bg-green-500 hover:bg-green-700 ` : `bg-gray-500 cursor-not-allowed`} rounded text-white px-4 py-1 flex-shrink-0`}
            type="submit"
            id="sendButton"
            onClick={handleSaveContext}
            disabled={!updated}
          >{loading ? <LoaderSymbol /> : ""} Guardar</button>
          {error ? <span className="text-gray-500 text-sm">Error: {error}</span> : ""}

        </div>
        <div className="pl-2">

          <span className="text-gray-500 text-sm">

            {lastUpdated &&

              <>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-3 w-3 mr-1 inline">
                  <path fillRule="evenodd" d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16Zm.75-13a.75.75 0 0 0-1.5 0v5c0 .414.336.75.75.75h4a.75.75 0 0 0 0-1.5h-3.25V5Z" clipRule="evenodd" />
                </svg>
                {lastUpdated}
              </>

            }

          </span>

        </div>

      </div>

      
      
    </div>
  );

};

export default SettingsContextsItem;
