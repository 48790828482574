// src/Companies.js
import React, { useEffect, useState } from "react";
import { useAuth, useUser } from "@clerk/clerk-react";
import { useParams, Link } from "react-router-dom";
import { API_URL, ATRIA_ADMINS } from "./utils/constants";
import { shortFormat, timeAgo } from "./utils/dateHelper";
import Menu from "./Menu";
import SettingsCompany from "./SettingsCompany";
import SettingsContext from "./SettingsContext";
import SettingsMedia from "./SettingsMedia";
import SettingsAnalytics from "./SettingsAnalytics";
import SettingsAnalyticsConversations from "./SettingsAnalyticsConversations";
import SettingsAnalyticsConversion from "./SettingsAnalyticsConversion";
import SettingsAnalyticsTags from "./SettingsAnalyticsTags";
import LoaderSymbol from "./icons/LoaderSymbol";


const Settings = () => {
  const { companyUrl, path } = useParams(); //Param
  const [company, setCompany] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showMenu, setShowMenu] = useState(false);
  const [error, setError] = useState(null);
  const { getToken } = useAuth(); // Get the token
  const fullUrl = window.location.href;
  const { user } = useUser();

  useEffect(() => {
    fetchCompany();
  }, [getToken, companyUrl]);


  //Fetch the company
  const fetchCompany = async () => {
    try {
      const token = await getToken(); // Get the token
      const response = await fetch(`${API_URL}company/${companyUrl}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setCompany(data);
    } catch (error) {
      //setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handlerToggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const handlerShowMenu = () => {
    setShowMenu(true);
  };

  const handlerHideMenu = () => {
    setShowMenu(false);
  };


  if (loading) {
    return (
      <div className="flex flex-row h-full w-full overflow-x-hidden">

        {/* Sidebar */}
        <div className="hidden sm:flex w-64 flex-col flex-none pt-4 bg-white flex-shrink-0 h-full">

        </div>
        {/* /Sidebar */}

        {/* Companies */}
        <div className="flex flex-col flex-auto grow h-full p-0 sm:p-6">
          <div className="flex flex-row flex-wrap rounded-xl bg-gray-100 p-4">
            <h4>Cargando...</h4>
          </div>
        </div>
        {/* /Companies */}

      </div>
    );
  }

  if (error) {
    return (
      <div className="flex flex-row h-full w-full overflow-x-hidden">

        {/* Sidebar */}
        <div className="hidden sm:flex w-64 flex-col flex-none pt-4 bg-white flex-shrink-0 h-full">

        </div>
        {/* /Sidebar */}

        {/* Companies */}
        <div className="flex flex-col flex-auto grow h-full p-0 sm:p-6">
          <div className="flex flex-row flex-wrap rounded-xl bg-gray-100 p-4">
            <h4>Error: {error}</h4>
          </div>
        </div>
        {/* /Companies */}

      </div>
    );
    ;
  }

  if (!company) {
    return (
      <div className="flex flex-row h-full w-full overflow-x-hidden">

        {/* Sidebar */}
        <div className="hidden sm:flex w-64 flex-col flex-none pt-4 bg-white flex-shrink-0 h-full">

        </div>
        {/* /Sidebar */}

        {/* Companies */}
        <div className="flex flex-col flex-auto grow h-full p-0 sm:p-6">
          <div className="flex flex-row flex-wrap rounded-xl bg-gray-100 p-4">
            <h4>No existe la empresa</h4>
          </div>
        </div>
        {/* /Companies */}

      </div>
    );
    ;
  }

  const currentDate = shortFormat(new Date(), company?.tz);

  return (

    <div className="flex flex-row h-full w-full overflow-x-hidden">

      {/* Sidebar */}
      <div className={`${showMenu?`flex w-full`:`hidden sm:flex w-64`} flex-col flex-none pt-4 bg-white flex-shrink-0 h-full`}>

        <Menu  company={company.data}/>

        <div className="flex flex-row items-center justify-center w-full mt-2 px-2">
          <div className="flex flex-col">
            <h2><Link to={`/company/${company.data?.url}`} >{company.data?.name}</Link></h2>
          </div>
        </div>

        {/* Settings */}
        
        <div className="flex flex-col space-y-1 mt-4 mx-0 h-full overflow-auto shadow-inner" id="conversationsList">

              <Link to={`/company/${companyUrl}`}
              className={`flex flex-row items-center hover:bg-green-100 px-2 py-2`}
              title={`Conversaciones`}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4 mr-2">
                    <path fillRule="evenodd" d="M11.78 5.22a.75.75 0 0 1 0 1.06L8.06 10l3.72 3.72a.75.75 0 1 1-1.06 1.06l-4.25-4.25a.75.75 0 0 1 0-1.06l4.25-4.25a.75.75 0 0 1 1.06 0Z" clipRule="evenodd" />
                  </svg>
                  Conversaciones
              </Link>

              <Link to={`/company/${companyUrl}/settings`}
                className={`flex flex-row items-center hover:bg-green-100 px-2 py-2 ${!path ? 'bg-green-500 hover:bg-green-700 text-white' : ''}`}
                title={`Configuración`}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4 mr-2">
                  <path fillRule="evenodd" d="M8.34 1.804A1 1 0 0 1 9.32 1h1.36a1 1 0 0 1 .98.804l.295 1.473c.497.144.971.342 1.416.587l1.25-.834a1 1 0 0 1 1.262.125l.962.962a1 1 0 0 1 .125 1.262l-.834 1.25c.245.445.443.919.587 1.416l1.473.294a1 1 0 0 1 .804.98v1.361a1 1 0 0 1-.804.98l-1.473.295a6.95 6.95 0 0 1-.587 1.416l.834 1.25a1 1 0 0 1-.125 1.262l-.962.962a1 1 0 0 1-1.262.125l-1.25-.834a6.953 6.953 0 0 1-1.416.587l-.294 1.473a1 1 0 0 1-.98.804H9.32a1 1 0 0 1-.98-.804l-.295-1.473a6.957 6.957 0 0 1-1.416-.587l-1.25.834a1 1 0 0 1-1.262-.125l-.962-.962a1 1 0 0 1-.125-1.262l.834-1.25a6.957 6.957 0 0 1-.587-1.416l-1.473-.294A1 1 0 0 1 1 10.68V9.32a1 1 0 0 1 .804-.98l1.473-.295c.144-.497.342-.971.587-1.416l-.834-1.25a1 1 0 0 1 .125-1.262l.962-.962A1 1 0 0 1 5.38 3.03l1.25.834a6.957 6.957 0 0 1 1.416-.587l.294-1.473ZM13 10a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" clipRule="evenodd" />
                </svg>
                Configuración
              </Link>

              <Link to={`/company/${companyUrl}/settings/contexts`}
                className={`flex flex-row items-center hover:bg-green-100 px-2 py-2 ${path === 'contexts' ? 'bg-green-500 hover:bg-green-700 text-white' : ''}`}
                title={`Contexto`}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4 mr-2">
                  <path d="M10 1a6 6 0 0 0-3.815 10.631C7.237 12.5 8 13.443 8 14.456v.644a.75.75 0 0 0 .572.729 6.016 6.016 0 0 0 2.856 0A.75.75 0 0 0 12 15.1v-.644c0-1.013.762-1.957 1.815-2.825A6 6 0 0 0 10 1ZM8.863 17.414a.75.75 0 0 0-.226 1.483 9.066 9.066 0 0 0 2.726 0 .75.75 0 0 0-.226-1.483 7.553 7.553 0 0 1-2.274 0Z" />
                </svg>
                Contexto
              </Link>

              <Link to={`/company/${companyUrl}/templates`}
                className={`flex flex-row items-center hover:bg-green-100 px-2 py-2 ${path === 'templates' ? 'bg-green-500 hover:bg-green-700 text-white' : ''}`}
                title={`Plantillas`}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4 mr-2">
                  <path d="M3 3.5A1.5 1.5 0 0 1 4.5 2h6.879a1.5 1.5 0 0 1 1.06.44l4.122 4.12A1.5 1.5 0 0 1 17 7.622V16.5a1.5 1.5 0 0 1-1.5 1.5h-11A1.5 1.5 0 0 1 3 16.5v-13Z" />
                </svg>
                  Plantillas
              </Link>

              <Link to={`/company/${companyUrl}/settings/media`}
              className={`flex flex-row items-center hover:bg-green-100 px-2 py-2 ${path==='media'?'bg-green-500 hover:bg-green-700 text-white':''}`}
              title={`Media`}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4 mr-2">
                <path fillRule="evenodd" d="M1 5.25A2.25 2.25 0 0 1 3.25 3h13.5A2.25 2.25 0 0 1 19 5.25v9.5A2.25 2.25 0 0 1 16.75 17H3.25A2.25 2.25 0 0 1 1 14.75v-9.5Zm1.5 5.81v3.69c0 .414.336.75.75.75h13.5a.75.75 0 0 0 .75-.75v-2.69l-2.22-2.219a.75.75 0 0 0-1.06 0l-1.91 1.909.47.47a.75.75 0 1 1-1.06 1.06L6.53 8.091a.75.75 0 0 0-1.06 0l-2.97 2.97ZM12 7a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z" clipRule="evenodd" />
              </svg>
                Media
              </Link>

              { ATRIA_ADMINS.includes(user?.id) &&
              <Link to={`/company/${companyUrl}/settings/analytics`}
                className={`flex flex-row items-center hover:bg-green-100 px-2 py-2 ${path === 'analytics' ? 'bg-green-500 hover:bg-green-700 text-white' : ''}`}
                title={`Media`}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4 mr-2">
                  <path fillRule="evenodd" d="M15.22 6.268a.75.75 0 0 1 .968-.431l5.942 2.28a.75.75 0 0 1 .431.97l-2.28 5.94a.75.75 0 1 1-1.4-.537l1.63-4.251-1.086.484a11.2 11.2 0 0 0-5.45 5.173.75.75 0 0 1-1.199.19L9 12.312l-6.22 6.22a.75.75 0 0 1-1.06-1.061l6.75-6.75a.75.75 0 0 1 1.06 0l3.606 3.606a12.695 12.695 0 0 1 5.68-4.974l1.086-.483-4.251-1.632a.75.75 0 0 1-.432-.97Z" clipRule="evenodd" />
                </svg>

                Analítica
              </Link>
              }

              { ATRIA_ADMINS.includes(user?.id) && fullUrl.includes('/analytics') && (
              <Link to={`/company/${companyUrl}/settings/analytics/conversations`}
                className={`flex flex-row items-center hover:bg-green-100 pl-4 pr-2 py-2 ${path === 'conversations' ? 'bg-green-500 hover:bg-green-700 text-white' : ''}`}
                title={`Media`}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4 mr-2">
                  <path fillRule="evenodd" d="M15.22 6.268a.75.75 0 0 1 .968-.431l5.942 2.28a.75.75 0 0 1 .431.97l-2.28 5.94a.75.75 0 1 1-1.4-.537l1.63-4.251-1.086.484a11.2 11.2 0 0 0-5.45 5.173.75.75 0 0 1-1.199.19L9 12.312l-6.22 6.22a.75.75 0 0 1-1.06-1.061l6.75-6.75a.75.75 0 0 1 1.06 0l3.606 3.606a12.695 12.695 0 0 1 5.68-4.974l1.086-.483-4.251-1.632a.75.75 0 0 1-.432-.97Z" clipRule="evenodd" />
                </svg>

                Conversaciones
              </Link>
              )}

              { ATRIA_ADMINS.includes(user?.id) && fullUrl.includes('/analytics') && (
              <Link to={`/company/${companyUrl}/settings/analytics/conversion`}
                className={`flex flex-row items-center hover:bg-green-100 pl-4 pr-2 py-2 ${path === 'conversion' ? 'bg-green-500 hover:bg-green-700 text-white' : ''}`}
                title={`Media`}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4 mr-2">
                  <path fillRule="evenodd" d="M15.22 6.268a.75.75 0 0 1 .968-.431l5.942 2.28a.75.75 0 0 1 .431.97l-2.28 5.94a.75.75 0 1 1-1.4-.537l1.63-4.251-1.086.484a11.2 11.2 0 0 0-5.45 5.173.75.75 0 0 1-1.199.19L9 12.312l-6.22 6.22a.75.75 0 0 1-1.06-1.061l6.75-6.75a.75.75 0 0 1 1.06 0l3.606 3.606a12.695 12.695 0 0 1 5.68-4.974l1.086-.483-4.251-1.632a.75.75 0 0 1-.432-.97Z" clipRule="evenodd" />
                </svg>

                Conversión
              </Link>
              )}

              { ATRIA_ADMINS.includes(user?.id) && fullUrl.includes('/analytics') && (
              <Link to={`/company/${companyUrl}/settings/analytics/tags`}
                className={`flex flex-row items-center hover:bg-green-100 pl-4 pr-2 py-2 ${path === 'tags' ? 'bg-green-500 hover:bg-green-700 text-white' : ''}`}
                title={`Media`}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4 mr-2">
                  <path fillRule="evenodd" d="M15.22 6.268a.75.75 0 0 1 .968-.431l5.942 2.28a.75.75 0 0 1 .431.97l-2.28 5.94a.75.75 0 1 1-1.4-.537l1.63-4.251-1.086.484a11.2 11.2 0 0 0-5.45 5.173.75.75 0 0 1-1.199.19L9 12.312l-6.22 6.22a.75.75 0 0 1-1.06-1.061l6.75-6.75a.75.75 0 0 1 1.06 0l3.606 3.606a12.695 12.695 0 0 1 5.68-4.974l1.086-.483-4.251-1.632a.75.75 0 0 1-.432-.97Z" clipRule="evenodd" />
                </svg>

                Etiquetas
              </Link>
              )}

        </div>
  
        {/* /Settings */}

      </div>
      {/* /Sidebar */}

      {/* SettingsCompany */}

      

      { path === "" || !path ? <SettingsCompany company={company} /> : ''}
      { path === "contexts" ? <SettingsContext /> : ''}
      { path === "media" ? <SettingsMedia /> : ''}
      { path === "analytics" ? <SettingsAnalytics /> : ''}
      { path === "conversations" ? <SettingsAnalyticsConversations/> : ''}
      { path === "conversion" ? <SettingsAnalyticsConversion/> : ''}
      { path === "tags" ? <SettingsAnalyticsTags/> : ''}
      
      
      {/* /SettingsCompany */}

    </div>
  );
};

export default Settings;
