// src/Companies.js
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "@clerk/clerk-react";
import { useParams } from "react-router-dom";
import { API_URL, PATH_CONTEXTS } from "./utils/constants";
import LoaderSymbol from "./icons/LoaderSymbol";
import SettingsContextsItem from "./SettingsContextsItem";


const SettingsContext = ({ company, handlerToggleMenu, handlerShowMenu, handlerHideMenu, showMenu }) => {
  const { companyUrl, templateid } = useParams(); //Param
  const [fields, setFields] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { getToken } = useAuth(); // Get the token

  const fetchContext = async () => {
    try {
      setLoading(true);
      const token = await getToken(); // Get the token
      const response = await fetch(`${API_URL}company/${companyUrl}/${PATH_CONTEXTS}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      //console.log(data);
      setFields(data);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSaveContext = async () => {
    try {
      setLoading(true);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  //Update Page title
  useEffect(() => {
    let title = "Contexto";

    if (title) {
      document.title = `${title} - ${companyUrl}`;
    } else {
      document.title = `${companyUrl}`;
    }

    fetchContext();

  }, []);

  if (error) {
    return (
      <div className="flex flex-col flex-auto grow h-full p-0 sm:p-6">
        <div className="flex flex-col flex-auto rounded-xl h-full bg-gray-100 p-0">
          <h4>Error: {error}</h4>
        </div>
      </div>
    );
    ;
  }

  if (!companyUrl) {
    return (
      <div className="flex flex-col flex-auto grow h-full p-0 sm:p-6">
        <div className="flex flex-col flex-auto rounded-xl h-full bg-gray-100 p-0">
          <h4>Empresa Inválida</h4>
        </div>
      </div>
    );
    ;
  }

      
  return (

    <div className={`${showMenu ? `hidden` : `flex`} flex-col flex-auto grow h-full p-0 sm:p-6`}>
      <div className="flex flex-col flex-auto rounded-xl h-full bg-gray-100 p-0">

        <div className="flex flex-col h-full overflow-x-auto" id="conversation">

          <h2 className="p-2 mt-2 mx-2 text-black">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4 mr-2 inline">
              <path d="M10 1a6 6 0 0 0-3.815 10.631C7.237 12.5 8 13.443 8 14.456v.644a.75.75 0 0 0 .572.729 6.016 6.016 0 0 0 2.856 0A.75.75 0 0 0 12 15.1v-.644c0-1.013.762-1.957 1.815-2.825A6 6 0 0 0 10 1ZM8.863 17.414a.75.75 0 0 0-.226 1.483 9.066 9.066 0 0 0 2.726 0 .75.75 0 0 0-.226-1.483 7.553 7.553 0 0 1-2.274 0Z" />
            </svg>
            Contexto
          </h2>

          {Array.isArray(fields) && fields?.map((field) => (
            <SettingsContextsItem key={field._id} context={field} />
          ))}

          {Array.isArray(fields) && fields.length === 0 &&
            <div className="p-3 rounded-lg bg-white shadow rounded-xl w-100 m-4">
              <h4>Sin información de contexto para modificar</h4>
            </div>
          }

          {fields === null &&
            <div className="p-3 rounded-lg bg-white shadow rounded-xl w-100 m-4">
              <h4>Cargando...</h4>
            </div>
          }

        </div>

      </div>
    </div>
  );
};

export default SettingsContext;
