// src/Companies.js
import React, { useEffect, useState, useRef, useMemo } from "react";
import { useAuth } from "@clerk/clerk-react";
import { useParams } from "react-router-dom";
import { API_URL } from "./utils/constants";
import ConversationTextbox from "./ConversationTextbox";
import ConversationHeader from "./ConversationHeader";
import ConversationMessage from "./ConversationMessage";
import ConversationsCount from "./ConversationsCount";
import AblyMessages from "./AblyMessages";
import * as Ably from 'ably'
import { AblyProvider,ChannelProvider } from 'ably/react';
import LoaderSymbol from "./icons/LoaderSymbol";

const Conversation = ({ company, tags, statuses, handlerToggleMenu, handlerShowMenu, handlerHideMenu, showMenu, handleRefreshConversations }) => {
  const {companyUrl, contactid } = useParams(); //Param
  //const [company, setCompany] = useState([]);
  //const [contact, setContact] = useState([]);
  const [conversation, setConversation] = useState([]);
  const [timestamp, setTimestamp] = useState(0);
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);
  const [lastUserMessage, setLastUserMessage] = useState(0);
  const [error, setError] = useState(null);
  const bottomRef = useRef(null);
  const scrollRef = useRef(null);
  const [scrolled, setScrolled] = useState(false);
  const { getToken } = useAuth(); // Get the token

  // Use useMemo to ensure ablyClient is not recreated on each render
  const ablyClient = useMemo(() => new Ably.Realtime({ key: process.env.REACT_APP_ABLY_KEY }), []);

  /**
   * Fetch the conversation
   */
  const fetchConversation = async () => {
    handlerHideMenu();
    setUpdating(true);
    try {
      const token = await getToken(); // Obtain the token
      console.log(`get Conversation ${companyUrl}/${contactid}`);
      const response = await fetch(`${API_URL}company/${companyUrl}/${contactid}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setConversation(data);
      if(data?.timestamp) setTimestamp(data?.timestamp);

      //Last user message Date
      let lastUserMessage=new Date("2024-01-01T00:00:00.000Z");
      if(data?.data && Array.isArray(data.data ) && data.data.length > 0){
        data.data.forEach(function(message) {
          if (message.type==='request') { 
            let msgDate=new Date(message.createdAt);
            if(msgDate > lastUserMessage) lastUserMessage=msgDate;
          }
        });
        setLastUserMessage(lastUserMessage);
      }

    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
      setUpdating(false);
    }
  };

  /**
   * Update the conversations
   */
  const updateConversation = async () => {
    handlerHideMenu();
    try {
      const token = await getToken(); // Obtain the token
      console.log(`get Conversation ${companyUrl}/${contactid}/timestamp/${timestamp}`);
      const response = await fetch(`${API_URL}company/${companyUrl}/${contactid}/timestamp/${timestamp}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      //console.log("Conversation: "+JSON.stringify(data));
      
      //Clone conversation and append data
      const conversationClone = {
        ...conversation,
        timestamp: data?.timestamp,
        contact: data?.contact,
        data: [...(conversation.data || []), ...(data?.data || [])]
      };

      // Update state with the new object
      if(data?.data) setConversation(conversationClone);
      //Update timestamp
      if(data?.timestamp) setTimestamp(data?.timestamp);

      //Last user message Date
      let lastUserMessage=new Date("2024-01-01T00:00:00.000Z");
      if(data?.data && Array.isArray(data.data ) && data.data.length > 0){
        data.data.forEach(function(message) {
          if (message.type==='request') { 
            let msgDate=new Date(message.createdAt);
            if(msgDate > lastUserMessage) lastUserMessage=msgDate;
          }
        });
        if(lastUserMessage.getTime()!==(new Date("2024-01-01T00:00:00.000Z")).getTime()) setLastUserMessage(lastUserMessage);
      }

    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  /**
   * Update the conversation
   */
  const handleConversationUpdate = () => {
    if(contactid){
      handleRefreshConversations();
      updateConversation();
    }
    else handlerShowMenu();
  };

  /**
   * Update if url changes
   */
  useEffect(() => {
    if(contactid) fetchConversation();
    else{
      setConversation(null);
      setLoading(false);
    }
  }, [contactid]);


  /**
   * Update title and scroll
   */
  useEffect(() => {

    //Scroll initial
    const scrollContainer = bottomRef?.current?.parentElement?.parentElement?.parentElement  || bottomRef?.current?.offsetParent?.offsetParent?.offsetParent;
    //console.log(`Scroll: ${scrolled} - Height: ${(scrollContainer?.scrollHeight - scrollContainer?.scrollTop - scrollContainer?.clientHeight)}`);
    if(scrolled){

      const heightBottom = (scrollContainer?.scrollHeight - scrollContainer?.scrollTop - scrollContainer?.clientHeight);
      //console.log(`scrollHeight: ${scrollContainer?.scrollHeight} - scrollTop: ${scrollContainer?.scrollTop} - clientHeight: ${scrollContainer?.clientHeight}`);
      //console.log(`diferencia: ${scrollContainer?.scrollHeight-scrollContainer?.scrollTop} - clientHeight: ${scrollContainer?.clientHeight}`);
      if ( (scrollContainer?.scrollHeight - scrollContainer?.scrollTop) <= (scrollContainer?.clientHeight * 2) ) {
        bottomRef.current?.scrollIntoView({ behavior: "instant" });
        //console.log("Scrolled1");
      }

    } else {

      bottomRef.current?.scrollIntoView({ behavior: "instant" });
      //console.log("Scrolled2");
      if (conversation?.data?.length > 0) setScrolled(true);

    }
    
    
    
    let title;

    if (conversation?.contact?.name) {
      title = conversation.contact.name;
    } else if (conversation?.contact?.contactid) {
      title = conversation.contact.contactid;
    } else {
      title = '';
    }
    
    if (title) {
      document.title = `${title} - ${company.data.name}`;
    } else {
      document.title = `${company.data.name?company.data.name:companyUrl}`;
    }

  }, [conversation, company]);

  if (loading) {
    return (
      <div className="flex flex-col flex-auto grow h-full p-0 sm:p-6">
        <div className="flex flex-col flex-auto rounded-xl h-full bg-gray-100 p-4">
          <LoaderSymbol loading={true} />
        </div>
      </div>
    );
  }

  if (error) {
    console.error(error);
    return (
      <div className="flex flex-col flex-auto grow h-full p-0 sm:p-6">
        <div className="flex flex-col flex-auto rounded-xl h-full bg-gray-100 p-0">
          <h4>Error. Refresh the page</h4>
        </div>
      </div>
    );
    ;
  }

  return (

      <div className={`${showMenu?`hidden sm:flex`:`flex`} flex-col flex-auto grow h-full p-0 sm:p-6`}>
        <div className="flex flex-col flex-auto rounded-xl h-full bg-gray-100 p-0">

          {conversation && conversation.data?
          <AblyProvider client={ ablyClient }>
            <ChannelProvider channelName={companyUrl}>
              <AblyMessages company={companyUrl} contactid={contactid} handlerUpdateConversation={handleConversationUpdate} handlerUpdateConversations={handleRefreshConversations} />
            </ChannelProvider>
          </AblyProvider>
          :<></>}

          {/* Header */}
          <ConversationHeader conversation={conversation} handlerUpdate={handleConversationUpdate} tags={tags} statuses={statuses} handlerToggleMenu={handlerToggleMenu} updating={updating}/>
          {/* /Header */}

          {/* Conversation */}
          {conversation && conversation.data?
          <div className="flex flex-col h-full overflow-x-auto" ref={scrollRef} id="conversation">

            <div className="flex flex-col h-full" key={"flex0"}>
              <div className="grid grid-cols-12 gap-y-2" key={"grid0"}>
                  {conversation.data?.map((message) => (
                    <React.Fragment key={message._id}>
                      <ConversationMessage message={message} handlerUpdate={handleConversationUpdate} company={company}/>
                    </React.Fragment>
                  ))}
                  <div ref={bottomRef} key={"bottomref"}/>
              </div>
            </div>

          </div>
          :<></>}
          {/* /Conversation */}

          {/* Dashboard */}
          {!conversation?
          <div className="flex flex-col h-full overflow-x-auto" id="conversation">

            <div className="flex flex-col h-full" key={"flex0"}>
              <div className="grid grid-cols-4 gap-4 p-4" key={"grid0"}>

                <ConversationsCount days={1} />

                <ConversationsCount days={7} />

                <ConversationsCount days={30} />

                <div ref={bottomRef} key={"bottomref"} />
              </div>
            </div>

          </div>
          :<></>}
          {/* /Dashboard */}

          {/* Textbox */}
          {conversation?.contact?
          <ConversationTextbox conversation={conversation} company={company} handlerUpdate={handleConversationUpdate} handleRefreshConversations={handleRefreshConversations} lastUserMessage={lastUserMessage}/>
          :<></>}
          {/* /Textbox */}

        </div>
      </div>
  );
};

export default Conversation;
