export const FRONT_URL = process.env.REACT_APP_FRONT_URL;
export const API_URL = process.env.REACT_APP_API_URL;
export const STATIC_URL = process.env.REACT_APP_STATIC_URL;
export const ENV = process.env.REACT_APP_ENV;

// App Configurations
export const ATRIA_ADMINS = [
    "user_2jfrcxT4m5ncUl6heGCLBXZZ8LR", //jorpcolombia test
    "user_2kRNb4j2ZfZ17p3FnlE35eQh4Yf", //jorpcolombia prod (admin)
    //"user_2kM2OQurFwXs23FW14xmtlRdoaA", //jorge (no admin)
    "user_2kRPIW482fsR5RAB1WbrDMHoQxj", //mauricio
    "user_2kRP800M0nwvCoHdqOjKerOvhOo" //ricardo
];
export const APP_NAME = "Atria";
export const CONFIG_DEFAULT_DAYS_BACK_CONVERSATIONS = 7;
export const CONFIG_TIME_CONVERSATION_WINDOW = 24*60*60*1000 - 10*60*1000;//24 Hours - 10 minutes gap to consider processing times
export const PATH_POWER_ON = "on";
export const PATH_POWER_OFF = "off";
export const PATH_SEND = "send";
export const PATH_RESET = "reset";
export const PATH_DOWNLOAD_TESTS = "downloadTests";
export const PATH_REPLICATE = "replicate";
export const PATH_TAGS = "tags";
export const PATH_TAG = "tag";
export const PATH_CHANGE_STATUS = "status";
export const PATH_UNTAG = "untag";
export const PATH_REMARKETING = "remarketing";
export const PATH_TEMPLATES_ADD = "templates/add";
export const PATH_SETTINGS = "settings";
export const PATH_CONTEXTS = "contexts";
export const PATH_CHANNELS = "channels";
export const PATH_MEDIA = "settings/media";
export const PATH_MEDIA_UPLOAD = "settings/media/upload"
export const PATH_CONVERSATIONS_COUNT = "getConversationsCount"
export const PATH_ANALYTICS = "settings/analytics"