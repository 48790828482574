import React, { useState, useEffect } from 'react';
import { useAuth } from "@clerk/clerk-react";
import { useParams } from "react-router-dom";
import { API_URL,PATH_TEMPLATES_ADD } from "./utils/constants";
import LoaderSymbol from "./icons/LoaderSymbol";

const WhatsAppTemplateCreator = ({fetchTemplates}) => {
  const { companyUrl } = useParams(); //Param
  const { getToken } = useAuth(); // Get the token
  const [template, setTemplate] = useState({
    name: '',
    category: '',
    language: 'es',
    body: '',
    examples: {}
  });

  const [error, setError] = useState('');
  const [result, setResult] = useState('');
  const [tokens, setTokens] = useState([]);
  const [loading, setLoading] = useState(false);

  const categories = [
    'MARKETING',
    'UTILITY'
  ];

  const languages = [
    { code: 'es', name: 'Español' },
    { code: 'es_AR', name: 'Español (Argentina)' },
    { code: 'es_ES', name: 'Español (España)' },
    { code: 'es_MX', name: 'Español (México)' },
    { code: 'en', name: 'Inglés' },
    { code: 'en_GB', name: 'Inglés (UK)' },
    { code: 'en_US', name: 'Inglés (EEUU)' },
    { code: 'pt_BR', name: 'Portugués (Brasil)' },
    { code: 'pt_PT', name: 'Portugués (Portugal)' },
  ];

  useEffect(() => {
    const tokenRegex = /{{(\d+)}}/g;
    const matches = [...template.body.matchAll(tokenRegex)];
    const uniqueTokens = [...new Set(matches.map(match => match[1]))].sort((a, b) => Number(a) - Number(b));
    setTokens(uniqueTokens);

    const newExamples = { ...template.examples };
    Object.keys(newExamples).forEach(key => {
      if (!uniqueTokens.includes(key)) {
        delete newExamples[key];
      }
    });
    setTemplate(prev => ({ ...prev, examples: newExamples }));
  }, [template.body]);

  const handleChange = (field, value) => {
    setTemplate(prev => ({
      ...prev,
      [field]: value
    }));
    setError('');
  };

  const handleExampleChange = (token, value) => {
    setTemplate(prev => ({
      ...prev,
      examples: {
        ...prev.examples,
        [token]: value
      }
    }));
  };

  const handleSubmit = async () => {
    if (!template.name || !template.category || !template.body) {
      setError('Por favor, completa todos los campos requeridos');
      return;
    }

    setLoading(true);

    // Format the examples array in the required order
    const examplesArray = tokens.map(token => template.examples[token] || '');

    // Create the formatted submission object
    const submissionData = {
      name: template.name,
      category: template.category,
      allow_category_change: true,
      language: template.language,
      components: [
        {
          type: "BODY",
          text: template.body,
          example: Array.isArray(examplesArray) && examplesArray.length > 0 ? {body_text: [examplesArray]} : undefined
        }
      ]
    };

    console.log(JSON.stringify(submissionData));

    try {
      const token = await getToken(); // Get the token
      let responsesubmit = await fetch(`${API_URL}company/${companyUrl}/${PATH_TEMPLATES_ADD}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(submissionData)
      });

      if(responsesubmit.status === 200){
        console.log('Template added:', JSON.stringify(submissionData, null, 2));
        setResult("✅ Plantilla creada");
        fetchTemplates();
      }
      else{
        const errorData = await responsesubmit.json();
        console.log(`❌ ${responsesubmit.status}: `+errorData.message);
        setResult(`❌ ${responsesubmit.status}: `+errorData.message );
      }
    } catch (error) {
      console.error('Error:', JSON.stringify(error));
      setError("❌ Error: "+error.message);
    }

    setLoading(false);
  };

  const getPreviewText = () => {
    let previewText = template.body;
    tokens.forEach(token => {
      const example = template.examples[token] || `[ejemplo ${token}]`;
      previewText = previewText.replace(new RegExp(`{{${token}}}`, 'g'), example);
    });
    return previewText;
  };

  return (
    <div className="max-w-4xl mx-auto p-4 space-y-6">
      <div className="bg-white rounded-lg shadow-lg overflow-hidden">
        <div className="p-6 border-b border-gray-200">
          <h2 className="text-xl font-semibold text-gray-900">
            Crear Plantilla de WhatsApp
          </h2>
        </div>

        <div className="p-6 space-y-6">
          
          {(
          <>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  Nombre de la Plantilla *
                </label>
                <input
                  type="text"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="ej., confirmacion_pedido"
                  value={template.name}
                  onChange={(e) => handleChange('name', e.target.value)}
                />
              </div>
              
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  Categoría *
                </label>
                <select
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  value={template.category}
                  onChange={(e) => handleChange('category', e.target.value)}
                >
                  <option value="">Seleccionar categoría</option>
                  {categories.map(category => (
                    <option key={category} value={category}>
                      {category.replace('_', ' ')}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">
                Idioma *
              </label>
              <select
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={template.language}
                onChange={(e) => handleChange('language', e.target.value)}
              >
                {languages.map(lang => (
                  <option key={lang.code} value={lang.code}>
                    {lang.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">
                Mensaje *
              </label>
              <textarea
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Ingresa tu mensaje. Usa {{1}}, {{2}}, {{3}} para variables"
                value={template.body}
                onChange={(e) => handleChange('body', e.target.value)}
                rows={4}
              />
              <p className="text-sm text-gray-500">
                {`Usa {{número}} para agregar variables (ejemplo: {{1}}, {{2}}, {{3}})`}
              </p>
            </div>

            {tokens.length > 0 && (
              <div className="space-y-4">
                <h3 className="text-sm font-medium text-gray-700">Ejemplos de Variables</h3>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  {tokens.map(token => (
                    <div key={token} className="space-y-2">
                      <label className="block text-sm font-medium text-gray-700">
                        Ejemplo para {`{{${token}}}`}
                      </label>
                      <input
                        type="text"
                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        placeholder={`Ejemplo para variable ${token}`}
                        value={template.examples[token] || ''}
                        onChange={(e) => handleExampleChange(token, e.target.value)}
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}

            <div className="flex justify-end pt-4">
              <div><LoaderSymbol loading={loading} /></div>
              <button
                type="button"
                className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                onClick={handleSubmit}
              >
                Crear Plantilla
              </button>
            </div>
          </>
          )}

          {result && (
          <div className="bg-yellow-50 text-yellow-700 p-4 rounded-lg flex items-center gap-2">
            {result}
          </div>
          )}

          {error && (
            <div className="bg-red-50 text-red-700 p-4 rounded-lg flex items-center gap-2">
              <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
              <span>{error}</span>
            </div>
          )}
        </div>
      </div>

      {!result && tokens.length>0 && (
        <div className="bg-white rounded-lg shadow-lg overflow-hidden">
          <div className="p-6 border-b border-gray-200">
            <h2 className="text-xl font-semibold text-gray-900">
              Vista Previa
            </h2>
          </div>
          <div className="p-6">
            <div className="bg-green-50 p-4 rounded-lg">
              <div className="whitespace-pre-wrap text-gray-800">
                {getPreviewText()}
              </div>
            </div>
          </div>
        </div>
      )}

    </div>
  );
};

export default WhatsAppTemplateCreator;