import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer, CartesianGrid,ReferenceLine } from 'recharts';



export default function Chart({data, isHourly}) {

  let formattedData;
  let average = 0;
  if(Array.isArray(data)){

    if(data.length > 0) average = data.reduce((acc, item) => acc + parseInt(item.total), 0) / data.length; //data.reduce((acc, item) => acc + item.total, 0) / data.length;

    console.log(`Average: ${average}`);

    formattedData = data?.map(item => ({
    date: isHourly ? (item.date?.split(' ')[1])?.substring(0, 5) : new Date(item.date).toLocaleDateString('en-US', { month: 'short', day: 'numeric' }),
    total: Number(item.total)
    }));

    return (
      <ResponsiveContainer width="100%" height={300}>
        <LineChart data={formattedData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date"/>
          <YAxis />
          <Tooltip />
          <Line type="monotone" dataKey="total" stroke="#48bb78" strokeWidth={2} isAnimationActive={false}/>
          <ReferenceLine y={average} stroke="#032e15" strokeWidth={1} strokeDasharray="3 3"/>
        </LineChart>
      </ResponsiveContainer>
    );
  }
  else{
    return (
      <p>Invalid data</p>
    );
  }

  
}