import React, { useEffect, useState, useRef } from "react";
import { useAuth } from "@clerk/clerk-react";
import { API_URL, PATH_RESET, PATH_TAG, PATH_DOWNLOAD_TESTS, PATH_CHANGE_STATUS } from "./utils/constants";

const ConversationHeader = ({ conversation, handlerUpdate, tags, statuses, setLoading }) => {
  const [visible, setVisible] = useState(false);
  const [error, setError] = useState(null);
  
  const dropdownRef = useRef(null);
  const { getToken } = useAuth(); // Get the token

  const toggleMenu = () => {
    setVisible(!visible);
  }

  const reset = async () => {
    try {
      setLoading(true);
      const resetConfirmation = window.confirm('Reiniciar conversacion?');
      if (!resetConfirmation) return;

      const token = await getToken(); // Get the token
      const response = await fetch(`${API_URL}company/${conversation?.contact?.company}/${conversation?.contact?.contactid}/${PATH_RESET}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      });
      if(response.status === 200){
        setVisible(false);
        console.log("RESET "+conversation?.contact?.contactid);
        handlerUpdate();
      }
    } catch (error) {
      console.log(error.message);
    }
    setLoading(false);
  };

  const downloadTests = async () => {
    try {

      const token = await getToken(); // Get the token
      const response = await fetch(`${API_URL}company/${conversation?.contact?.company}/${conversation?.contact?.contactid}/${PATH_DOWNLOAD_TESTS}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      });
      if(response.status === 200){
        setVisible(false);
        console.log("DOWNLOAD TESTS "+conversation?.contact?.contactid);

        const date = new Date();
        const formattedDate = 
          String(date.getFullYear()) +     // Last two digits of the year
          String(date.getMonth() + 1).padStart(2, '0') +  // Month (1-based, with leading zero)
          String(date.getDate()).padStart(2, '0') + '-' +     // Day (with leading zero)
          String(date.getHours()).padStart(2, '0') +      // Hours (with leading zero)
          String(date.getMinutes()).padStart(2, '0') +    // Minutes (with leading zero)
          String(date.getSeconds()).padStart(2, '0');     // Seconds (with leading zero)


        // Convert the response to a Blob and create a download link
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);

        // Create a temporary <a> element to trigger the download
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${formattedDate}-${conversation?.contact?.company}-${conversation?.contact?.contactid}.csv`); // Set the file name
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link); // Clean up
      }
      else{
        throw new Error('Network response was not ok');
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const changeStatus = async (status) => {
    try {
      setLoading(true);
      const statusChangeConfirmation = window.confirm(`Cambiar status a ${status}?`);
      if (!statusChangeConfirmation) return;

      const token = await getToken(); // Get the token
      const response = await fetch(`${API_URL}company/${conversation.contact?.company}/${conversation.contact?.contactid}/${PATH_CHANGE_STATUS}/${status}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      });
      if(response.status === 200){
        setVisible(false);
        console.log("CHANGE_STATUS "+status);
        handlerUpdate();
      }
    } catch (error) {
      setError(error.message);
    }
    setLoading(false);
  };

  const tagConversation = async (tag) => {
    try {
      setLoading(true);
      const token = await getToken(); // Get the token
      const response = await fetch(`${API_URL}company/${conversation.contact?.company}/${conversation.contact?.contactid}/${PATH_TAG}/${tag}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      });
      if(response.status === 200){
        setVisible(false);
        console.log("TAG "+tag);
        handlerUpdate();
      }
    } catch (error) {
      setError(error.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setVisible(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  

  if (error) {
    return <div>Error</div>;
  }

  return (
    <div className="ml-4 flex-none">
          {/* Dots button */}
          <button 
            id="dropdownButton"
            className="text-gray-500 focus:outline-none"
            onClick={toggleMenu}>
            <svg className="w-6 h-6" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
              <path d="M10 3a1.5 1.5 0 110 3 1.5 1.5 0 010-3zM10 8.5a1.5 1.5 0 110 3 1.5 1.5 0 010-3zM11.5 15.5a1.5 1.5 0 10-3 0 1.5 1.5 0 003 0z"></path>
            </svg>
          </button>
          {/* /Dots button */}
        
          {/* Dropdown menu */}
          <div id="dropdownMenu"
            className={`absolute right-0 mt-2 w-64 bg-white border border-gray-200 rounded-md shadow-lg z-50 overflow-y-auto ${visible?'':'hidden'}`}
            style={{ maxHeight: '400px' }}
            ref={dropdownRef}>

            <div className="p-2">

              <a href="#" onClick={(e) => { e.preventDefault(); reset(); }} className="block px-4 py-2 text-gray-800 hover:bg-gray-100">♻️ Reiniciar</a>
              <a href="#" onClick={(e) => { e.preventDefault(); downloadTests(); }} className="block px-4 py-2 text-gray-800 hover:bg-gray-100">🧪 Pruebas</a>

              {(tags && Array.isArray(tags) && tags.length > 0)?
                <>
                  <hr className="my-2"/>
                  <p>Etiqueta:</p>
                </>
              :<></>}

              {(tags && Array.isArray(tags) && tags.length > 0)?
                tags.map((tag) => (
                  <a href="#" className="block px-2 py-2 text-gray-800 hover:bg-gray-100" key={tag.url} onClick={(e) => { e.preventDefault(); tagConversation(tag.url); }}>
                    <div className="inline-block h-2 w-2 rounded-full mr-1" style={{background:tag.backgroundColor}}></div> {tag.name}
                  </a>
                ))
              :<></>}

              {(statuses && Object.keys(statuses).length > 0)?
                <p><hr/>Cambiar status:</p>
              :<></>}

              {(statuses && Array.isArray(statuses) && statuses.length > 0)?
                statuses.map((status) => (
                  (status)?
                  <a href="#" className="block px-4 py-2 text-gray-800 hover:bg-gray-100" key={status.status} onClick={(e) => { e.preventDefault(); changeStatus(status.status); }}>
                    🔀 Status {status.status}
                  </a>
                  :<></>
                ))
              :<></>}

            </div>

          </div>
          {/* /Dropdown menu */}
        </div>
  );
};

export default ConversationHeader;
