// src/Companies.js
import React, { useEffect, useState } from "react";
import { useAuth } from "@clerk/clerk-react";
import { useParams } from "react-router-dom";
import { API_URL, PATH_CONVERSATIONS_COUNT } from "./utils/constants";
import LoaderSymbol from "./icons/LoaderSymbol";

const ConversationsCount = ({ days }) => {
  const { companyUrl, contactid } = useParams(); //Param
  const [dashboardLoading, setDashboardLoading] = useState(true);
  const [error, setError] = useState(null);
  const [conversationsCount, setConversationsCount] = useState(null);
  const { getToken } = useAuth(); // Get the token

  // Fetch the conversations stats
  const fetchConversationsCount = async () => {

    setDashboardLoading(true);
    try {
      const token = await getToken(); // Obtain the token
      const response = await fetch(`${API_URL}company/${companyUrl}/${PATH_CONVERSATIONS_COUNT}/${days})`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setConversationsCount(data);
    } catch (error) {
      setError(error.message);
    } finally {
      setDashboardLoading(false);
    }
  };

  useEffect(() => {
    fetchConversationsCount();
  }, []);

  if (dashboardLoading) {
    return (
      <div className="p-3 w-64 rounded-lg bg-white shadow rounded-xl text-center">
        <LoaderSymbol loading={true} />
      </div>
    );
  }

  if (error) {
    console.error(error);
    return (
      <div className="p-3 w-64 rounded-lg bg-white shadow rounded-xl text-center">
        <p>Error</p>
      </div>
    );
  }

  return (
    <>
      {
        Array.isArray(conversationsCount) && conversationsCount.length > 0 &&
        <div className="p-3 rounded-lg bg-white shadow rounded-xl text-center">
          <p>{days === 1 ?
            <>Últimas 24 horas</>
            :
            <>Últimos {days} días</>}
          </p>
          <p className="text-3xl font-bold">{conversationsCount[0].distinct_contactids}</p>
        </div>
      }
    </>

  );

};

export default ConversationsCount;
