// src/Companies.js
import React, { useEffect, useState } from "react";
import { useAuth } from "@clerk/clerk-react";
import { useParams, Link } from "react-router-dom";
import { API_URL, CONFIG_DEFAULT_DAYS_BACK_CONVERSATIONS, PATH_TAGS, PATH_SETTINGS } from "./utils/constants";
import { shortFormat, timeAgo } from "./utils/dateHelper";
import Menu from "./Menu";
import Conversation from "./Conversation";
import LoaderSymbol from "./icons/LoaderSymbol";


const Company = () => {
  const { companyUrl, contactid } = useParams(); //Param
  const [company, setCompany] = useState([]);
  const [conversations, setConversations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [fetchingConversations, setFetchingConversations] = useState(false);
  const [error, setError] = useState(null);
  const [tags, setTags] = useState([]);
  const [statuses, setStatuses] = useState();
  const [showSearch, setShowSearch] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterDate, setFilterDate] = useState(CONFIG_DEFAULT_DAYS_BACK_CONVERSATIONS);
  const [filterHot, setFilterHot] = useState(0); // Default value
  const [filterTag, setFilterTag] = useState('');  // Default value
  const [filteredConversations, setFilteredConversations] = useState([]);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const { getToken } = useAuth(); // Get the token

  useEffect(() => {
    if(!fetchingConversations){
      setFetchingConversations(true);
      console.log(`Effect1 ${companyUrl}`);
      setLoadingSearch(true);
      handlerShowMenu();
      fetchCompany();
      fetchConversations();
      fetchTags();
      setFetchingConversations(false);
    }
  }, [companyUrl]);

  const handleRefreshConversations = (e) => {
    setLoadingSearch(true);
    fetchConversations();
  };

  // Fetch the conversations
  const fetchConversations = async () => {
    try {
      setLoadingSearch(true);
      const token = await getToken(); // Get the token
      const queryString = new URLSearchParams({ days: filterDate }).toString();
      console.log(`get Conversations ${companyUrl}`);
      const response = await fetch(`${API_URL}company/${companyUrl}/conversations${queryString ? '?' + queryString : ''}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setConversations(data);
      setFilteredConversations(data.data);
    } catch (error) {
      setError(error.message);
    } finally {
      //setLoadingSearch(false);
    }
  };

  //Fetch the company
  const fetchCompany = async () => {
    try {
      const token = await getToken(); // Get the token
      console.log(`fetchCompany ${companyUrl}`)
      const response = await fetch(`${API_URL}company/${companyUrl}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setCompany(data);
      if(data.data.status){
        setStatuses(data.data.status);
        console.log("Status:"+ Object.keys(data.data.status));
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchTags = async () => {
    try {
      const token = await getToken(); // Obtain the token
      console.log(`get Tags ${companyUrl}`);
      const response = await fetch(`${API_URL}company/${companyUrl}/${PATH_TAGS}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      //console.log(data)
      setTags(data);
    } catch (error) {
      setError(error.message);
    }
  };


  const handleSearch = (e) => {
    const { name, value } = e.target;
    if (name === 'searchTerm') {
      setSearchTerm(value);
    } else if (name === 'filterHot') {
      setFilterHot(value);
    } else if (name === 'filterTag') {
      setFilterTag(value);
    } else if (name === 'filterDate') {
      setFilterDate(value);
    }
  };

  const handleShowSearch = (e) => {
    setShowSearch(!showSearch);
  };

  const handlerToggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const handlerShowMenu = () => {
    setShowMenu(true);
  };

  const handlerHideMenu = () => {
    setShowMenu(false);
  };

  /**
   * Wait 200ms until update the searchterm
   * @param {*} value 
   * @param {*} delay 
   * @returns 
   */
  const useDebounce = (value, delay) => {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    }, [value, delay]);

    return debouncedValue;
  };
  const debouncedSearchTerm = useDebounce(searchTerm, 300);


  /**
   * Search use Effect
   */
  useEffect(() => {
    setLoadingSearch(true);  // Show loading spinner
    const timeoutId = setTimeout(() => {
      // Perform filtering logic after debouncing or filter change
      let filteredConversations = conversations.data?.filter(conversation => {
        // Text search filter (name and contactid)
        const matchesSearchTerm = conversation.name?.toLowerCase().includes(debouncedSearchTerm?.toLowerCase()) ||
          conversation.contactid?.toLowerCase().includes(debouncedSearchTerm?.toLowerCase());

        // Hot filter (if selected)
        const matchesHotFilter = filterHot === '0' || conversation.messagesCount >= parseInt(filterHot);

        // Tag filter (if selected)
        const matchesTagFilter = !filterTag || filterTag === '' || filterTag === '0' || conversation.tags?.includes(filterTag);

        return matchesSearchTerm && matchesHotFilter && matchesTagFilter;
      });

      setFilteredConversations(filteredConversations);
      
      setLoadingSearch(false);

    }, 500);  // Simulate a slight delay for loading (500ms)

    return () => clearTimeout(timeoutId);  // Cleanup timeout on unmount
  }, [debouncedSearchTerm, filterHot, filterTag, conversations]);

  /**
   * Date change Effect
   */
  useEffect(() => {
    fetchConversations();
  }, [filterDate]);


  if (loading) {
    return (
      <div className="flex flex-row h-full w-full overflow-x-hidden">

        {/* Sidebar */}
        <div className="hidden sm:flex w-64 flex-col flex-none pt-4 bg-white flex-shrink-0 h-full">

        </div>
        {/* /Sidebar */}

        {/* Companies */}
        <div className="flex flex-col flex-auto grow h-full p-0 sm:p-6">
          <div className="flex flex-row flex-wrap rounded-xl bg-gray-100 p-4">
            <LoaderSymbol loading={true} />
          </div>
        </div>
        {/* /Companies */}

      </div>
    );
  }

  if (error) {
    console.error(error);
  }

  if (!company) {
    return (
      <div className="flex flex-row h-full w-full overflow-x-hidden">

        {/* Sidebar */}
        <div className="hidden sm:flex w-64 flex-col flex-none pt-4 bg-white flex-shrink-0 h-full">

        </div>
        {/* /Sidebar */}

        {/* Companies */}
        <div className="flex flex-col flex-auto grow h-full p-0 sm:p-6">
          <div className="flex flex-row flex-wrap rounded-xl bg-gray-100 p-4">
            <h4>No existe la empresa</h4>
          </div>
        </div>
        {/* /Companies */}

      </div>
    );
    ;
  }

  const currentDate = shortFormat(new Date(), company?.data?.tz);

  return (

    <div className="flex flex-row h-full w-full overflow-x-hidden">

      {/* Sidebar */}
      <div className={`${showMenu?`flex w-full sm:w-64`:`hidden sm:flex w-64`} flex-col flex-none pt-4 bg-white flex-shrink-0 h-full`}>

        <Menu company={company.data}/>

        <div className="flex flex-row items-center justify-center w-full mt-2 px-2">
          <div className="flex flex-col">
            <h2>
              <Link to={`/company/${company.data?.url}`} >{company.data?.name}</Link>
            </h2>
          </div>
        </div>

        <div className="flex flex-col mt-2 w-full px-2">

          <div className={`${showSearch?'':'hidden'} flex flex-col text-sm`}>

            <select name="filterDate" onChange={handleSearch} className="mt-1 p-1 border rounded-xl focus:outline-none focus:border-indigo-300 shadow-inner" value={filterDate.toString()}>
              <option value="1" key={1}>24 horas</option>
              <option value="7" key={7}>1 semana</option>
              <option value="15" key={15}>2 semanas</option>
              <option value="30" key={30}>1 mes</option>
            </select>

            <input
              type="text"
              placeholder="Buscar..."
              onChange={handleSearch}
              name="searchTerm"
              className="mt-1 p-1 border rounded-xl focus:outline-none focus:border-indigo-300 shadow-inner"
            />

            <select name="filterHot" onChange={handleSearch} className="mt-1 p-1 border rounded-xl focus:outline-none focus:border-indigo-300 shadow-inner"  defaultValue={'0'}>
              <option value="0" key={0}></option>
              <option value="3" key={3}>🌶️</option>
              <option value="9" key={9}>🌶️🌶️</option>
            </select>

            
            {(tags && Array.isArray(tags) && tags.length > 0) ?
              <select name="filterTag" onChange={handleSearch} className="mt-1 p-1 border rounded-xl focus:outline-none focus:border-indigo-300 shadow-inner" defaultValue={'0'}>
                  <option value="0" key={0}></option>
                {tags.map((tag) => (
                  <option value={tag.url} key={tag.url}>{tag.name}</option>
                ))}
              </select>
              : <></>}
            
          </div>

          <div className="flex flex-row items-center justify-between text-sm flex-none mt-2" onClick={handleShowSearch}>
            <span className="cursor-pointer">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" width="12" height="16" className="inline-block mr-1">
                <path d="M10 3.75a2 2 0 1 0-4 0 2 2 0 0 0 4 0ZM17.25 4.5a.75.75 0 0 0 0-1.5h-5.5a.75.75 0 0 0 0 1.5h5.5ZM5 3.75a.75.75 0 0 1-.75.75h-1.5a.75.75 0 0 1 0-1.5h1.5a.75.75 0 0 1 .75.75ZM4.25 17a.75.75 0 0 0 0-1.5h-1.5a.75.75 0 0 0 0 1.5h1.5ZM17.25 17a.75.75 0 0 0 0-1.5h-5.5a.75.75 0 0 0 0 1.5h5.5ZM9 10a.75.75 0 0 1-.75.75h-5.5a.75.75 0 0 1 0-1.5h5.5A.75.75 0 0 1 9 10ZM17.25 10.75a.75.75 0 0 0 0-1.5h-1.5a.75.75 0 0 0 0 1.5h1.5ZM14 10a2 2 0 1 0-4 0 2 2 0 0 0 4 0ZM10 16.25a2 2 0 1 0-4 0 2 2 0 0 0 4 0Z" />
              </svg>
              Conversaciones <LoaderSymbol loading={loadingSearch} /></span>
            <span className="flex items-center justify-center text-green-500">
              {filteredConversations ? filteredConversations.length : 0}
            </span>
          </div>
          
          
        </div>

        {/* Conversations */}
        <div className="flex flex-col space-y-1 mt-4 mx-0 h-full overflow-auto shadow-inner" id="conversationsList">

          <div className="flex flex-row text-gray-500 text-xs px-2 py-1">
            <p className="text-left flex-grow">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" width="12" height="16" className="inline-block mr-1">
                <path fillRule="evenodd" d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16Zm.75-13a.75.75 0 0 0-1.5 0v5c0 .414.336.75.75.75h4a.75.75 0 0 0 0-1.5h-3.25V5Z" clipRule="evenodd" />
              </svg>
              {currentDate}</p>

            <div onClick={handleRefreshConversations} className="flex-none bg-gray-500 hover:bg-gray-700 rounded text-white px-1 pb-1 cursor-pointer">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" width="12" height="12" className="inline-block">
                <path fillRule="evenodd" d="M13.836 2.477a.75.75 0 0 1 .75.75v3.182a.75.75 0 0 1-.75.75h-3.182a.75.75 0 0 1 0-1.5h1.37l-.84-.841a4.5 4.5 0 0 0-7.08.932.75.75 0 0 1-1.3-.75 6 6 0 0 1 9.44-1.242l.842.84V3.227a.75.75 0 0 1 .75-.75Zm-.911 7.5A.75.75 0 0 1 13.199 11a6 6 0 0 1-9.44 1.241l-.84-.84v1.371a.75.75 0 0 1-1.5 0V9.591a.75.75 0 0 1 .75-.75H5.35a.75.75 0 0 1 0 1.5H3.98l.841.841a4.5 4.5 0 0 0 7.08-.932.75.75 0 0 1 1.025-.273Z" clipRule="evenodd" />
              </svg>
            </div>

          </div>

          {filteredConversations?.map((conversation) => (
            <Link key={conversation.contactid}
              to={`/company/${conversation.company}/${conversation.contactid}`}
              className={`flex flex-row items-center ${(conversation.contactid === contactid) ? "bg-green-500 hover:bg-green-700 text-white " : "hover:bg-green-100"} p-2`}
              title={conversation.contactid}>

              <div className="">

                <div className="text-sm font-semibold align-bottom">
                  {(conversation.agent_connected) ? <div className="inline-block h-3 w-3 bg-green-500 border border-white rounded-full mr-1"></div> : <div className="inline-block h-3 w-3 bg-red-500 border border-white rounded-full mr-1"></div>}
                  {conversation.name ? <>{conversation.name} <small className={(conversation.contactid === contactid) ? `text-white hover:text-green-100` : `text-gray-500 hover:text-gray-700`}>{conversation.contactid}</small></> : conversation.contactid}
                </div>

                <div className={`pt-1 text-xs ${(conversation.contactid === contactid) ? `text-white` : `text-gray-500`} align-bottom`}>
                  <span className="mr-1">{timeAgo(conversation.createdAt, company?.data?.tz)}</span>
                  {conversation.messagesCount >= 9 ?
                    (<>🌶️🌶️</>) :
                    (conversation.messagesCount >= 3 ?
                      (<>🌶️</>) :
                      '')
                  }

                  {conversation.status ? <abbr title={`Status: ${conversation.status}`} className="mr-2">🔀{conversation.status}</abbr> : <></>}

                  {conversation?.matchedTags && conversation.matchedTags.map((tag, index) => (
                    <div
                      key={index}
                      className={`rounded px-1 py-1 mr-1 bg-gray-200 inline-block whitespace-nowrap`}
                      style={{
                        background: tag.backgroundColor ? tag.backgroundColor : '#DDD',
                        color: tag.textColor ? tag.textColor : '#000',
                        borderColor: tag.textColor ? tag.textColor : '#000',
                      }}
                    >
                      {tag.name}
                    </div>
                  ))}

                </div>

              </div>
            </Link>
          ))}

        </div>
        {/* /Conversations */}

      </div>
      {/* /Sidebar */}

      {/* Conversation */}
      {error?
      <></>:
      <Conversation company={company} tags={tags} statuses={statuses} showMenu={showMenu} handlerToggleMenu={handlerToggleMenu} handlerShowMenu={handlerShowMenu} handlerHideMenu={handlerHideMenu} handleRefreshConversations={handleRefreshConversations}/>}
      {/* /Conversation */}

    </div>
  );
};

export default Company;
